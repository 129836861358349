import React, { useState, useEffect } from "react";
import styles from "../css/Player.module.css";
import axios from "axios";
import Navbar from "./Navbar";
import logo from "../backgrounds/AIKs.webp";
import Footer from "./Footer";
import Loading from "./Loading";
import { Helmet } from "react-helmet-async";

function PlayerId(props) {
  const player = props.data;
  const initialYear = props.data2;

  const [teamId, setTeamId] = useState("");

  const [getTrophies, setGetTrophies] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(initialYear);
  const [fixtures, setFixtures] = useState("");
  const [selectedFixture, setSelectedFixture] = useState("");
  const [jerseyNumber, setJerseyNumber] = useState(0);

  const [swedishTeam, setSwedishTeam] = useState("");
  const [swedishCupMatches, setSwedishCupMatches] = useState(0);
  const [swedishCupGoals, setSwedishCupGoals] = useState(0);
  const [swedishCupAssists, setSwedishCupAssist] = useState(0);
  const [swedishCupMinutes, setSwedishCupMinutes] = useState(0);
  const [swedishCupReds, setSwedishCupReds] = useState(0);
  const [swedishCupYellows, setSwedishCupYellows] = useState(0);

  const [europeTeam, setEuropeTeam] = useState("");
  const [europaMatches, setEuropaMatches] = useState(0);
  const [europaGoals, setEuropaGoals] = useState(0);
  const [europaAssists, setEuropaAssist] = useState(0);
  const [europaMinutes, setEuropaMinutes] = useState(0);
  const [europaReds, setEuropaReds] = useState(0);
  const [europaYellows, setEuropaYellows] = useState(0);
  const [europeImage, setEuropeImage] = useState("");

  const [friendliesTeam, setFriendliesTeam] = useState("");
  const [friendliesMatches, setFriendliesMatches] = useState(0);
  const [friendliesGoals, setFriendliesGoals] = useState(0);
  const [friendliesAssists, setFriendliesAssist] = useState(0);
  const [friendliesMinutes, setFriendliesMinutes] = useState(0);
  const [friendliesReds, setFriendliesReds] = useState(0);
  const [friendliesYellows, setFriendliesYellows] = useState(0);

  const [nationalsTeam, setNationalsTeam] = useState("");
  const [nationalsMatches, setNationalsMatches] = useState(0);
  const [nationalsGoals, setNationalsGoals] = useState(0);
  const [nationalsAssists, setNationalsAssist] = useState(0);
  const [nationalsMinutes, setNationalsMinutes] = useState(0);
  const [nationalsReds, setNationalsReds] = useState(0);
  const [nationalsYellows, setNationalsYellows] = useState(0);

  const [league5Team, setLeague5Team] = useState("");
  const [league5Matches, setLeague5Matches] = useState(0);
  const [league5Goals, setLeague5Goals] = useState(0);
  const [league5Assists, setLeague5Assist] = useState(0);
  const [league5Minutes, setLeague5Minutes] = useState(0);
  const [league5Reds, setLeague5Reds] = useState(0);
  const [league5Yellows, setLeague5Yellows] = useState(0);

  const [league6Team, setLeague6Team] = useState("");
  const [league6Matches, setLeague6Matches] = useState(0);
  const [league6Goals, setLeague6Goals] = useState(0);
  const [league6Assists, setLeague6Assist] = useState(0);
  const [league6Minutes, setLeague6Minutes] = useState(0);
  const [league6Reds, setLeague6Reds] = useState(0);
  const [league6Yellows, setLeague6Yellows] = useState(0);

  const [league7Team, setLeague7Team] = useState("");
  const [league7Matches, setLeague7Matches] = useState(0);
  const [league7Goals, setLeague7Goals] = useState(0);
  const [league7Assists, setLeague7Assist] = useState(0);
  const [league7Minutes, setLeague7Minutes] = useState(0);
  const [league7Reds, setLeague7Reds] = useState(0);
  const [league7Yellows, setLeague7Yellows] = useState(0);

  const [league8Team, setLeague8Team] = useState("");
  const [league8Matches, setLeague8Matches] = useState(0);
  const [league8Goals, setLeague8Goals] = useState(0);
  const [league8Assists, setLeague8Assist] = useState(0);
  const [league8Minutes, setLeague8Minutes] = useState(0);
  const [league8Reds, setLeague8Reds] = useState(0);
  const [league8Yellows, setLeague8Yellows] = useState(0);

  const [mainLeague, setMainLeague] = useState("");
  const [league, setLeague] = useState("");
  const [league2, setLeague2] = useState("");
  const [league3, setLeague3] = useState("");
  const [league4, setLeague4] = useState("");
  const [league5, setLeague5] = useState("");
  const [league6, setLeague6] = useState("");
  const [league7, setLeague7] = useState("");
  const [league8, setLeague8] = useState("");

  const [matchData, setMatchData] = useState("");

  const [playersData, setPlayersData] = useState({});
  const [svenskaCupenData, setSvenskaCupenData] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [loaded4, setLoaded4] = useState(false);

  const [analysis, setAnalysis] = useState('');

  const selectedSvenskaCupenYear = selectedSeason - 1;

  useEffect(() => {
    const options = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/players",
      params: { id: player, season: selectedSeason },
    };

    axios
      .request(options)
      .then(function (response) {
        const data = response.data;
        setPlayersData(data.response[0]);
       // console.log(data.response[0])
        setTeamId(data.response[0].statistics[0].team.id);
        setLoaded(true);

        // rightside bottom data

        if (data.response[0].statistics[1] == undefined) {
          setSwedishTeam("");
          setSwedishCupMatches(0);
          setSwedishCupGoals(0);
          setSwedishCupAssist(0);
          setSwedishCupMinutes(0);
          setSwedishCupReds(0);
          setSwedishCupYellows(0);
          setLeague("Ingen data");
        } else {
          setSwedishCupMatches(
            data.response[0].statistics[1].games.appearences + 0
          );
          setSwedishTeam(data.response[0].statistics[1].team.name);
          setSwedishCupGoals(data.response[0].statistics[1].goals.total + 0);
          setSwedishCupAssist(data.response[0].statistics[1].goals.assists + 0);
          setSwedishCupMinutes(
            data.response[0].statistics[1].games.minutes + 0
          );
          setSwedishCupReds(data.response[0].statistics[1].cards.red + 0);
          setSwedishCupYellows(data.response[0].statistics[1].cards.yellow + 0);
          setLeague(data.response[0].statistics[1].league.name);
        }

        if (data.response[0].statistics[2] == undefined) {
          setEuropeTeam("");
          setEuropaMatches(0);
          setEuropaGoals(0);
          setEuropaAssist(0);
          setEuropaMinutes(0);
          setEuropaReds(0);
          setEuropaYellows(0);
          setEuropeImage(0);

          setLeague2("Ingen data");
        } else {
          setEuropaMatches(
            data.response[0].statistics[2].games.appearences + 0
          );
          setEuropeTeam(data.response[0].statistics[2].team.name);
          setEuropaGoals(data.response[0].statistics[2].goals.total + 0);
          setEuropaAssist(data.response[0].statistics[2].goals.assists + 0);
          setEuropaMinutes(data.response[0].statistics[2].games.minutes + 0);
          setEuropaReds(data.response[0].statistics[2].cards.red + 0);
          setEuropaYellows(data.response[0].statistics[2].cards.yellow + 0);
          setEuropeImage(data.response[0].statistics[2].league.logo);
          setLeague2(data.response[0].statistics[2].league.name);
        }

        if (data.response[0].statistics[3] == undefined) {
          setFriendliesTeam("");
          setFriendliesMatches(0);
          setFriendliesGoals(0);
          setFriendliesAssist(0);
          setFriendliesMinutes(0);
          setFriendliesReds(0);
          setFriendliesYellows(0);

          setLeague3("Ingen data");
        } else {
          setFriendliesMatches(
            data.response[0].statistics[3].games.appearences + 0
          );
          setFriendliesTeam(data.response[0].statistics[3].team.name);
          setFriendliesGoals(data.response[0].statistics[3].goals.total + 0);
          setFriendliesAssist(data.response[0].statistics[3].goals.assists + 0);
          setFriendliesMinutes(
            data.response[0].statistics[3].games.minutes + 0
          );
          setFriendliesReds(data.response[0].statistics[3].cards.red + 0);
          setFriendliesYellows(data.response[0].statistics[3].cards.yellow + 0);
          setLeague3(data.response[0].statistics[3].league.name);
        }

        if (data.response[0].statistics[4] == undefined) {
          setNationalsTeam("");
          setNationalsMatches(0);
          setNationalsGoals(0);
          setNationalsAssist(0);
          setNationalsMinutes(0);
          setNationalsReds(0);
          setNationalsYellows(0);
          setLeague4("Ingen data");
        } else {
          setNationalsMatches(
            data.response[0].statistics[4].games.appearences + 0
          );
          setNationalsTeam(data.response[0].statistics[4].team.name);
          setNationalsGoals(data.response[0].statistics[4].goals.total + 0);
          setNationalsAssist(data.response[0].statistics[4].goals.assists + 0);
          setNationalsMinutes(data.response[0].statistics[4].games.minutes + 0);
          setNationalsReds(data.response[0].statistics[4].cards.red + 0);
          setNationalsYellows(data.response[0].statistics[4].cards.yellow + 0);
          setLeague4(data.response[0].statistics[4].league.name);
        }

        if (data.response[0].statistics[5] == undefined) {
          setLeague5Team("");
          setLeague5Matches(0);
          setLeague5Goals(0);
          setLeague5Assist(0);
          setLeague5Minutes(0);
          setLeague5Reds(0);
          setLeague5Yellows(0);
          setLeague5("Ingen data");
        } else {
          setLeague5Matches(
            data.response[0].statistics[5].games.appearences + 0
          );
          setLeague5Team(data.response[0].statistics[5].team.name);
          setLeague5Goals(data.response[0].statistics[5].goals.total + 0);
          setLeague5Assist(data.response[0].statistics[5].goals.assists + 0);
          setLeague5Minutes(data.response[0].statistics[5].games.minutes + 0);
          setLeague5Reds(data.response[0].statistics[5].cards.red + 0);
          setLeague5Yellows(data.response[0].statistics[5].cards.yellow + 0);
          setLeague5(data.response[0].statistics[5].league.name);
        }

        if (data.response[0].statistics[6] == undefined) {
          setLeague6Team("");
          setLeague6Matches(0);
          setLeague6Goals(0);
          setLeague6Assist(0);
          setLeague6Minutes(0);
          setLeague6Reds(0);
          setLeague6Yellows(0);
          setLeague6("Ingen data");
        } else {
          setLeague6Matches(
            data.response[0].statistics[6].games.appearences + 0
          );
          setLeague6Team(data.response[0].statistics[6].team.name);
          setLeague6Goals(data.response[0].statistics[6].goals.total + 0);
          setLeague6Assist(data.response[0].statistics[6].goals.assists + 0);
          setLeague6Minutes(data.response[0].statistics[6].games.minutes + 0);
          setLeague6Reds(data.response[0].statistics[6].cards.red + 0);
          setLeague6Yellows(data.response[0].statistics[6].cards.yellow + 0);
          setLeague6(data.response[0].statistics[6].league.name);
        }

        if (data.response[0].statistics[7] == undefined) {
          setLeague7Team("");
          setLeague7Matches(0);
          setLeague7Goals(0);
          setLeague7Assist(0);
          setLeague7Minutes(0);
          setLeague7Reds(0);
          setLeague7Yellows(0);
          setLeague7("Ingen data");
        } else {
          setLeague7Matches(
            data.response[0].statistics[7].games.appearences + 0
          );
          setLeague7Team(data.response[0].statistics[7].team.name);
          setLeague7Goals(data.response[0].statistics[7].goals.total + 0);
          setLeague7Assist(data.response[0].statistics[7].goals.assists + 0);
          setLeague7Minutes(data.response[0].statistics[7].games.minutes + 0);
          setLeague7Reds(data.response[0].statistics[7].cards.red + 0);
          setLeague7Yellows(data.response[0].statistics[7].cards.yellow + 0);
          setLeague7(data.response[0].statistics[7].league.name);
        }

        if (data.response[0].statistics[8] == undefined) {
          setLeague8Team("");
          setLeague8Matches(0);
          setLeague8Goals(0);
          setLeague8Assist(0);
          setLeague8Minutes(0);
          setLeague8Reds(0);
          setLeague8Yellows(0);
          setLeague8("Ingen data");
        } else {
          setLeague8Matches(
            data.response[0].statistics[8].games.appearences + 0
          );
          setLeague8Team(data.response[0].statistics[8].team.name);
          setLeague8Goals(data.response[0].statistics[8].goals.total + 0);
          setLeague8Assist(data.response[0].statistics[8].goals.assists + 0);
          setLeague8Minutes(data.response[0].statistics[8].games.minutes + 0);
          setLeague8Reds(data.response[0].statistics[8].cards.red + 0);
          setLeague8Yellows(data.response[0].statistics[8].cards.yellow + 0);
          setLeague8(data.response[0].statistics[8].league.name);
        }

        setMainLeague(data.response[0].statistics[0].league.name);
      })

      .catch(function (error) {
        console.error(error);
      });

    const seasons = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/seasons",
      params: { player: player },
    };

    axios
      .request(seasons)
      .then(function (response) {
        setSeasons(response.data.response.reverse());
      })
      .catch(function (error) {
        console.error(error);
      });
    const options4 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/fixtures",
      params: { team: teamId, season: selectedSeason },
    };

    axios
      .request(options4)
      .then(function (response) {
        // console.log(response.data.response);
        setFixtures(response.data.response);
        setLoaded2(true);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [selectedSeason]);

  useEffect(() => {
    const options = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/players",
      params: { id: player, season: selectedSvenskaCupenYear },
    };

    axios
      .request(options)
      .then(function (response) {
        const data = response.data;
        const stats = data.response[0].statistics;
        const svenskaCupenStat = stats.find(
          (stats) => stats.league.name === "Svenska Cupen"
        );
        setSvenskaCupenData(svenskaCupenStat);
        setLoaded4(true);
      })

      .catch(function (error) {
        console.error(error);
      });
  }, [selectedSeason]);

  /*
  useEffect(() => {
    const options5 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/matchData",
      params: { fixture: selectedFixture },
    };

    axios
      .request(options5)
      .then(function (response) {
       // console.log(response.data);
        setMatchData(response.data.response);
        setLoaded3(true);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [selectedFixture]);
*/
  useEffect(() => {
    const options3 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/trophies",
      params: { player: player },
    };

    axios
      .request(options3)
      .then(function (response) {
        setGetTrophies(response.data.response);
      })
      .catch(function (error) {
        console.error(error);
      });

    const transfers = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/transfers",
      params: { player: player },
    };

    axios
      .request(transfers)
      .then(function (response) {
        setTransfers(response.data.response[0].transfers);
      })
      .catch(function (error) {
        console.error(error);
      });

    const numbers = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/numbers",
      params: { player: player },
    };
    axios
      .request(numbers)
      .then(function (response) {
        // console.log(response.data);
        setJerseyNumber(response.data.response[0].players[0].number);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  // Leftside data

  if (loaded === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  /* if (loaded3 === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
*/
  let rating = 0;
  if (playersData.statistics[0].games.rating === null) {
    rating = 0;
  } else {
    rating = playersData.statistics[0].games.rating.slice(0, 4);
  }

  const allsvenskanImage = playersData.statistics[0].league.logo;
  const name = playersData.player.name;
  const team = playersData.statistics[0].team.name;
  const matches = playersData.statistics[0].games.appearences + 0;
  const minutes = playersData.statistics[0].games.minutes + 0;
  const substituteIn = playersData.statistics[0].substitutes.in + 0;
  const substituteOut = playersData.statistics[0].substitutes.out + 0;
  const goals = playersData.statistics[0].goals.total + 0;
  const assists = playersData.statistics[0].goals.assists + 0;
  const yellow = playersData.statistics[0].cards.yellow + 0;
  const red = playersData.statistics[0].cards.red + 0;
  const position = playersData.statistics[0].games.position;
  const penaltyGoals = playersData.statistics[0].penalty.scored + 0;
  const missedPenalties = playersData.statistics[0].penalty.missed + 0;
  const causedPenalties = playersData.statistics[0].penalty.commited + 0;
  const wonPenalties = playersData.statistics[0].penalty.won + 0;
  const totalPasses = playersData.statistics[0].passes.total + 0;
  const keyPasses = playersData.statistics[0].passes.key + 0;
  const passingAccuracy = playersData.statistics[0].passes.accuracy + 0;
  const blocks = playersData.statistics[0].tackles.blocks + 0;
  const interceptions = playersData.statistics[0].tackles.interceptions + 0;
  const wonDuels = playersData.statistics[0].duels.won + 0;
  const totalDuels = playersData.statistics[0].duels.total + 0;
  const fouls = playersData.statistics[0].fouls.committed + 0;
  const receivedFouls = playersData.statistics[0].fouls.drawn + 0;
  const shots = playersData.statistics[0].shots.total + 0;
  const shotsOn = playersData.statistics[0].shots.on + 0;
  const dribbles = playersData.statistics[0].dribbles.attempts + 0;
  const successDribbles = playersData.statistics[0].dribbles.success + 0;

  /*
  const svenskaCupenData = playersData.statistics.find(stats => stats.league.name === 'Svenska Cupen' && stats.league.season === selectedSeason - 1);
  console.log(svenskaCupenData);
*/
  let goalsAverage = goals / matches;
  let goalsAverageSlice = 0;
  if (goalsAverage === 0 || goalsAverage === NaN) {
    goalsAverageSlice = 0;
  } else {
    goalsAverageSlice = goalsAverage.toString().substring(0, 5);
  }

  //rightside data

  const image = playersData.player.photo;
  const firstName = playersData.player.firstname;
  const lastName = playersData.player.lastname;
  const nationality = playersData.player.nationality;
  const birthday = playersData.player.birth.date;
  const birthCity = playersData.player.birth.place;
  const age = playersData.player.age - 1;
  const height = playersData.player.height;
  const weight = playersData.player.weight;
  const skadad = playersData.player.injured;

  // Svenska Cupen Data

  const svenskaCupenLeagueName =
    svenskaCupenData && svenskaCupenData.league && svenskaCupenData.league.name;
  const svenskaCupenTeam =
    svenskaCupenData && svenskaCupenData.team && svenskaCupenData.team.name;
  const svenskaCupenMatches =
    (svenskaCupenData &&
      svenskaCupenData.games &&
      svenskaCupenData.games.appearences) ||
    0;
  const svenskaCupenGoals =
    (svenskaCupenData &&
      svenskaCupenData.goals &&
      svenskaCupenData.goals.total) ||
    0;
  const svenskaCupenAssists =
    (svenskaCupenData &&
      svenskaCupenData.goals &&
      svenskaCupenData.goals.assists) ||
    0;
  const svenskaCupenMinutes =
    (svenskaCupenData &&
      svenskaCupenData.games &&
      svenskaCupenData.games.minutes) ||
    0;
  const svenskaCupenReds =
    (svenskaCupenData &&
      svenskaCupenData.cards &&
      svenskaCupenData.cards.red) ||
    0;
  const svenskaCupenYellows =
    (svenskaCupenData &&
      svenskaCupenData.cards &&
      svenskaCupenData.cards.yellow) ||
    0;
  const svenskaCupenSeason =
    svenskaCupenData &&
    svenskaCupenData.league &&
    svenskaCupenData.league.season;

  //Matchspecifik data

  /* if (matchData[0] === 'undefined') {
    return
      }
      else if (
        matchData[0].team.id === 377 || matchData[1].team.id === 377 &&
        matchData[0].players[0].player.id === player
      ) {
        const minutesMatch = matchData[0].players.statistics[0].games.minutes + 0;
        const goalsMatch = matchData[0].players.statistics[0].goals.total + 0;
        const assistsMatch = matchData[0].players.statistics[0].goals.assists + 0;
        const yellowMatch = matchData[0].players.statistics[0].cards.yellow + 0;
        const redMatch = matchData[0].players.statistics[0].cards.red + 0;
        const penaltyGoalsMatch =
          matchData[0].players.statistics[0].penalty.scored + 0;
        const missedPenaltiesMatch =
          matchData[0].players.statistics[0].penalty.scored + 0;
        const totalPassesMatch =
          matchData[0].players.statistics[0].passes.total + 0;
        const keyPassesMatch = matchData[0].players.statistics[0].passes.key + 0;
        const blocksMatch = matchData[0].players.statistics[0].tackles.blocks + 0;
        const interceptionsMatch =
          matchData[0].players.statistics[0].tackles.interceptions + 0;
        const wonDuelsMatch = matchData[0].players.statistics[0].duels.won + 0;
        const totalDuelsMatch = matchData[0].players.statistics[0].duels.total + 0;
        const foulsMatch = matchData[0].players.statistics[0].fouls.committed + 0;
        const receivedFoulsMatch =
          matchData[0].players.statistics[0].fouls.drawn + 0;
        const shotsMatch = matchData[0].players.statistics[0].shots.total + 0;
        const shotsOnMatch = matchData[0].players.statistics[0].shots.on + 0;
        const dribblesMatch =
          matchData[0].players.statistics[0].dribbles.attempts + 0;
        const successDribblesMatch =
          matchData[0].players.statistics[0].dribbles.success + 0;
      } else {
       
      } */

      const analyzePlayerPerformance = async (playersData) => {
        try {
          console.log('Sending player data:', playersData);
          const response = await fetch('https://aikstats-backend.onrender.com/analyzePlayer', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(playersData),
          });
      
          const analysisText = await response.json();
          console.log(analysisText);
          return analysisText;
        } catch (error) {
          console.error('Error:', error);
        }
      };

      const handleAnalyzeButtonClick = async () => {
       
      
        try {
          const analysisText = await analyzePlayerPerformance(playersData);
          setAnalysis(analysisText);
        } catch (error) {
          console.error('Error:', error);
        }
      };

  if (loaded === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (loaded2 === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div className={styles.parent}>
      <Helmet>
        <title>
          {" "}
          {firstName} {lastName} - Statistik & Fakta | AIKstatistik.se
        </title>
      </Helmet>
      <div className={styles.leftSide}>
        <Navbar />
        <h1 className={styles.playerName}> {name}</h1>
        <h2 className={styles.signingTitleMobile}>
          {(() => {
            if (jerseyNumber === 0) {
              return null;
            } else {
              return jerseyNumber;
            }
          })()}{" "}
        </h2>
        <img
          className={styles.playerImage}
          width="300px"
          height="300px"
          src={image}
          alt="Player image"
        ></img>

        <h3 className={styles.bigMenu}>
          {mainLeague}{" "}
          <img
            className={styles.cupLogo}
            src={allsvenskanImage}
            alt="League logo"
          ></img>
        </h3>
        <p className={styles.position}>
          {(() => {
            if (team === "AIK stockholm") {
              return "AIK";
            } else if (team === "AIK Stockholm") {
              return "AIK";
            } else {
              return team;
            }
          })()}{" "}
        </p>
        <p className={styles.season}>
          Säsong:{" "}
          <select
            className={styles.customSelect}
            onChange={(e) => setSelectedSeason(e.target.value)}
          >
            {seasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>{" "}
        </p>
        <p className={styles.position}>
          Roll:{" "}
          {(() => {
            if (position === "Defender") {
              return "Försvarare";
            } else if (position === "Midfielder") {
              return "Mittfältare";
            } else if (position === "Attacker") {
              return "Anfallare";
            } else {
              return position;
            }
          })()}
        </p>
        <p className={styles.position}>
          Status:{" "}
          {(() => {
            if (skadad === false) {
              return (
                <span key={crypto.randomUUID()} style={{ color: "#ffdd00" }}>
                  Frisk
                </span>
              );
            } else if (skadad === true) {
              return (
                <span key={crypto.randomUUID()} style={{ color: "#D70040" }}>
                  Skadad
                </span>
              );
            }
          })()}
        </p>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.matches}>Matcher: {matches}</p>
          <p className={styles.minutes}>Minuter: {minutes}</p>
        </div>
        <div className={styles.flexbox}>
          <p className={styles.minutes}>Inbytt: {substituteIn}</p>
          <p className={styles.minutes}>Utbytt: {substituteOut}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Betyg: {rating}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Mål: {goals}</p>
          <p className={styles.goals}>Assist: {assists}</p>
        </div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Målsnitt: {goalsAverageSlice}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p>Röda: {red}</p>
          <p>Gula: {yellow}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Straffar</h3>
        <div className={styles.flexbox}>
          <p>Straffmål: {penaltyGoals}</p>
          <p>Missade: {missedPenalties}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Passningar</h3>
        <div className={styles.flexbox}>
          <p>Totala: {totalPasses}</p>
          <p>Nyckelpassningar: {keyPasses}</p>
        </div>

        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Tacklingar</h3>
        <div className={styles.flexbox}>
          <p>Blockar: {blocks}</p>
          <p>Brytningar: {interceptions}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Vunna dueller: {wonDuels}</p>
          <p>Totala: {totalDuels}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Regelbrott</h3>
        <div className={styles.flexbox}>
          <p>Orsakade: {fouls}</p>
          <p>Mottagna: {receivedFouls}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Skott</h3>
        <div className={styles.flexbox}>
          <p>Totala: {shots}</p>
          <p>På mål: {shotsOn}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Dribblingar</h3>
        <div className={styles.flexbox}>
          <p>Försök: {dribbles}</p>
          <p>Lyckade: {successDribbles}</p>
        </div>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.playerImage}
            width="260px"
            height="260px"
            src={image}
            alt="Player image"
          ></img>
          <div className={styles.nameBox}>
            {" "}
            <h3 className={styles.playerNameBig}>
              {" "}
              {firstName.split(' ').slice(0, 2).join(' ')}
              <br></br>
              {lastName}
              <br></br>{" "}
              <span className={styles.bigNumber}>
                {(() => {
                  if (jerseyNumber === 0) {
                    return "";
                  } else if (jerseyNumber === null) {
                    return "";
                  } else {
                    return jerseyNumber;
                  }
                })()}
              </span>
            </h3>
          </div>
        </div>
        <div className={styles.compInfo}>
          {svenskaCupenLeagueName && (
            <div className={styles.league6Box}>
              <h2 className={styles.cupTitle}>{svenskaCupenLeagueName} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (svenskaCupenTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (svenskaCupenTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return svenskaCupenTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {svenskaCupenMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {svenskaCupenGoals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {svenskaCupenAssists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {svenskaCupenMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {svenskaCupenReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {svenskaCupenYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league == "Ingen data" || league === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.swedishCupBox}>
              <h2 className={styles.cupTitle}>{league} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (swedishTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (swedishTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return swedishTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {swedishCupMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {swedishCupGoals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {swedishCupAssists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {swedishCupMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {swedishCupReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {swedishCupYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league2 == "Ingen data" || league2 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.europeBox}>
              <h2 className={styles.cupTitle}>{league2} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (europeTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (europeTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return europeTeam;
                  }
                })()}{" "}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {europaMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {europaGoals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {europaAssists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {europaMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {europaReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {europaYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league3 == "Ingen data" || league3 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.friendliesBox}>
              <h2 className={styles.cupTitle}>{league3} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (friendliesTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (friendliesTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return friendliesTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {friendliesMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {friendliesGoals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {friendliesAssists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {friendliesMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {friendliesReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {friendliesYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league4 == "Ingen data" || league4 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.nationalsBox}>
              <h2 className={styles.cupTitle}>{league4} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (nationalsTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (nationalsTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return nationalsTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {nationalsMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {nationalsGoals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {nationalsAssists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {nationalsMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {nationalsReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {nationalsYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league5 == "Ingen data" || league5 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.league5Box}>
              <h2 className={styles.cupTitle}>{league5} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (league5Team === "AIK stockholm") {
                    return "AIK";
                  } else if (league5Team === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return league5Team;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {league5Matches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {league5Goals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {league5Assists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {league5Minutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {league5Reds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {league5Yellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league6 == "Ingen data" || league6 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.league6Box}>
              <h2 className={styles.cupTitle}>{league6} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (league6Team === "AIK stockholm") {
                    return "AIK";
                  } else if (league6Team === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return league6Team;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {league6Matches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {league6Goals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {league6Assists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {league6Minutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {league6Reds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {league6Yellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
           {league7 == "Ingen data" || league7 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.league7Box}>
              <h2 className={styles.cupTitle}>{league7} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (league7Team === "AIK stockholm") {
                    return "AIK";
                  } else if (league7Team === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return league7Team;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {league7Matches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {league7Goals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {league7Assists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {league7Minutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {league7Reds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {league7Yellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
           {league8 == "Ingen data" || league8 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.league8Box}>
              <h2 className={styles.cupTitle}>{league8} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (league8Team === "AIK stockholm") {
                    return "AIK";
                  } else if (league8Team === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return league8Team;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {league8Matches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Mål: {league8Goals}</h3>
              <div className={styles.borderBox}></div>
              <h3>Assist: {league8Assists}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {league8Minutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {league8Reds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {league8Yellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          <div id="ezoic-pub-ad-placeholder-101"> </div>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>
        <h1 className={styles.factBox}>Faktaruta</h1>
        <p className={styles.nationality}>
          Namn: {firstName} {lastName}
        </p>
        <p className={styles.nationality}>Nationalitet: {nationality}</p>

        <p>Födelsedag: {birthday}</p>
        <p>Födelsestad: {birthCity}</p>
        <p>Ålder: {age}</p>
        <p>Längd: {height}</p>
        <p>Vikt: {weight}</p>
        <div className={styles.border}></div>
        <h1 className={styles.factBox}>Placeringar</h1>
        {getTrophies.map((trophy) => {
          return (
            <p key={crypto.randomUUID()} className={styles.titleP}>
              {trophy.season} - {trophy.league} - {trophy.place}
            </p>
          );
        })}
        <div className={styles.border}></div>
        <h1 className={styles.factBox}>Övergångar</h1>
        {transfers.map((transfer) => {
          return (
            <p key={crypto.randomUUID()} className={styles.titleP}>
              {transfer.date} - Till{" "}
              {(() => {
                if (transfer.teams.in.name === "AIK stockholm") {
                  return "AIK";
                } else if (transfer.teams.in.name === "AIK Stockholm") {
                  return "AIK";
                } else {
                  return transfer.teams.in.name;
                }
              })()}{" "}
              Från{" "}
              {(() => {
                if (transfer.teams.out.name === "AIK stockholm") {
                  return "AIK";
                } else if (transfer.teams.out.name === "AIK Stockholm") {
                  return "AIK";
                } else {
                  return transfer.teams.out.name;
                }
              })()}
              - {transfer.type}
            </p>
          );
        })}
        <div className={styles.border}></div>
        {/*  <button onClick={handleAnalyzeButtonClick}>Analyze Player Performance</button>
        <p>{analysis}</p> */}

        
        {/* 
        <h1 className={styles.factBox}>Match</h1>
        <select
          className={styles.customSelect}
          onChange={(e) => setSelectedFixture(e.target.value)}
        >
          {fixtures
            .sort((a, b) => {
              return a.fixture.timestamp - b.fixture.timestamp;
            })
            .map((fixture) => (
              <option key={fixture.fixture.id} value={fixture.fixture.id}>
                {(() => {
                  if (fixture.teams.home.name === "AIK stockholm") {
                    return "AIK";
                  } else {
                    return fixture.teams.home.name;
                  }
                })()}{" "}
                {fixture.score.fulltime.home} - {fixture.score.fulltime.away}{" "}
                {(() => {
                  if (fixture.teams.away.name === "AIK stockholm") {
                    return "AIK";
                  } else {
                    return fixture.teams.away.name;
                  }
                })()}{" "}
                | {fixture.fixture.date.slice(0, 10)}
              </option>
            ))}{" "}
        </select>
        <div className={styles.flexbox}>
          <p className={styles.minutes}>Minuter: {minutes}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Betyg: {rating}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Mål: {goals}</p>
          <p className={styles.goals}>Assist: {assists}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p>Röda: {red}</p>
          <p>Gula: {yellow}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Straffar</h3>
        <div className={styles.flexbox}>
          <p>Straffmål: {penaltyGoals}</p>
          <p>Missade: {missedPenalties}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Passningar</h3>
        <div className={styles.flexbox}>
          <p>Totala: {totalPasses}</p>
          <p>Nyckelpassningar: {keyPasses}</p>
        </div>

        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Tacklingar</h3>
        <div className={styles.flexbox}>
          <p>Blockar: {blocks}</p>
          <p>Brytningar: {interceptions}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Vunna dueller: {wonDuels}</p>
          <p>Totala: {totalDuels}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Regelbrott</h3>
        <div className={styles.flexbox}>
          <p>Orsakade: {fouls}</p>
          <p>Mottagna: {receivedFouls}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Skott</h3>
        <div className={styles.flexbox}>
          <p>Totala: {shots}</p>
          <p>På mål: {shotsOn}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Dribblingar</h3>
        <div className={styles.flexbox}>
          <p>Försök: {dribbles}</p>
          <p>Lyckade: {successDribbles}</p>
        </div>
        <div className={styles.border}></div> */}
      </div>

      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default PlayerId;
