import React from 'react'
import PlayerId from '../../components/PlayerId';


function LucasMuhl() {

   const player = "25606";
   const initialYear = "2023"

  return (
    <>
    <PlayerId data={player} data2={initialYear}/>
    </>
  )
}

export default LucasMuhl