import React from 'react'
import PlayerId from '../../components/PlayerId';


function Bilal() {

   const player = "48148";
   const initialYear = "2023"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }
export default Bilal