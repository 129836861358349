import React from 'react'
import PlayerId from '../../components/PlayerId';


function Antonsson() {

   const player = "1967";

   const initialYear = "2023"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default Antonsson