import React, { useState, useEffect } from "react";
import styles from "../css/Player.module.css";
import axios from "axios";
import Navbar from "./Navbar";
import logo from "../backgrounds/AIKs.webp";
import Footer from "./Footer";
import Loading from "./Loading";
import { Helmet } from "react-helmet-async";

function PlayerIdGoalie(props) {
  const player = props.data;
  const initialYear = props.data2;

  const [getTrophies, setGetTrophies] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [seasons, setSeasons] = useState([]);

  const [selectedSeason, setSelectedSeason] = useState(initialYear);
  const [selectedPlayer, setSelectedPlayer] = useState(player);
  const [selectedPlayerName, setSelectedPlayerName] = useState("");
  const [jerseyNumber, setJerseyNumber] = useState(0);

  const [swedishTeam, setSwedishTeam] = useState("");
  const [swedishCupMatches, setSwedishCupMatches] = useState(0);
  const [swedishCupSaves, setSwedishCupSaves] = useState(0);
  const [swedishCupConceded, setSwedishCupConceded] = useState(0);
  const [swedishCupMinutes, setSwedishCupMinutes] = useState(0);
  const [swedishCupReds, setSwedishCupReds] = useState(0);
  const [swedishCupYellows, setSwedishCupYellows] = useState(0);

  const [europeTeam, setEuropeTeam] = useState("");
  const [europaMatches, setEuropaMatches] = useState(0);
  const [europaSaves, setEuropaSaves] = useState(0);
  const [europaConceded, setEuropaConceded] = useState(0);
  const [europaMinutes, setEuropaMinutes] = useState(0);
  const [europaReds, setEuropaReds] = useState(0);
  const [europaYellows, setEuropaYellows] = useState(0);
  const [europeImage, setEuropeImage] = useState("");

  const [friendliesTeam, setFriendliesTeam] = useState("");
  const [friendliesMatches, setFriendliesMatches] = useState(0);
  const [friendliesSaves, setFriendliesSaves] = useState(0);
  const [friendliesConceded, setFriendliesConceded] = useState(0);
  const [friendliesMinutes, setFriendliesMinutes] = useState(0);
  const [friendliesReds, setFriendliesReds] = useState(0);
  const [friendliesYellows, setFriendliesYellows] = useState(0);

  const [nationalsTeam, setNationalsTeam] = useState("");
  const [nationalsMatches, setNationalsMatches] = useState(0);
  const [nationalsSaves, setNationalsSaves] = useState(0);
  const [nationalsConceded, setNationalsConceded] = useState(0);
  const [nationalsMinutes, setNationalsMinutes] = useState(0);
  const [nationalsReds, setNationalsReds] = useState(0);
  const [nationalsYellows, setNationalsYellows] = useState(0);

  const [league5Team, setLeague5Team] = useState("");
  const [league5Matches, setLeague5Matches] = useState(0);
  const [league5Saves, setLeague5Saves] = useState(0);
  const [league5Conceded, setLeague5Conceded] = useState(0);
  const [league5Minutes, setLeague5Minutes] = useState(0);
  const [league5Reds, setLeague5Reds] = useState(0);
  const [league5Yellows, setLeague5Yellows] = useState(0);

  const [league6Team, setLeague6Team] = useState("");
  const [league6Matches, setLeague6Matches] = useState(0);
  const [league6Saves, setLeague6Saves] = useState(0);
  const [league6Conceded, setLeague6Conceded] = useState(0);
  const [league6Minutes, setLeague6Minutes] = useState(0);
  const [league6Reds, setLeague6Reds] = useState(0);
  const [league6Yellows, setLeague6Yellows] = useState(0);

  const [mainLeague, setMainLeague] = useState("");
  const [league, setLeague] = useState("");
  const [league2, setLeague2] = useState("");
  const [league3, setLeague3] = useState("");
  const [league4, setLeague4] = useState("");
  const [league5, setLeague5] = useState("");
  const [league6, setLeague6] = useState("");

  const [playersData, setPlayersData] = useState({});
  const [svenskaCupenData, setSvenskaCupenData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loaded4, setLoaded4] = useState(false);

  const selectedSvenskaCupenYear = selectedSeason - 1;

  useEffect(() => {
    const options = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/players",
      params: { id: player, season: selectedSeason },
    };

    axios
      .request(options)
      .then(function (response) {
        const data = response.data;

        setPlayersData(data.response[0]);
        setLoaded(true);

        // rightside bottom data

        if (data.response[0].statistics[1] == undefined) {
          setSwedishTeam("");
          setSwedishCupMatches(0);
          setSwedishCupSaves(0);
          setSwedishCupConceded(0);
          setSwedishCupMinutes(0);
          setSwedishCupReds(0);
          setSwedishCupYellows(0);

          setLeague("Ingen data");
        } else {
          setSwedishCupMatches(
            data.response[0].statistics[1].games.appearences + 0
          );
          setSwedishTeam(data.response[0].statistics[1].team.name);
          setSwedishCupSaves(data.response[0].statistics[1].goals.saves + 0);
          setSwedishCupConceded(
            data.response[0].statistics[1].goals.conceded + 0
          );
          setSwedishCupMinutes(
            data.response[0].statistics[1].games.minutes + 0
          );
          setSwedishCupReds(data.response[0].statistics[1].cards.red + 0);
          setSwedishCupYellows(data.response[0].statistics[1].cards.yellow + 0);
          setLeague(data.response[0].statistics[1].league.name);
        }

        if (data.response[0].statistics[2] == undefined) {
          setEuropeTeam("");
          setEuropaMatches(0);
          setEuropaSaves(0);
          setEuropaConceded(0);
          setEuropaMinutes(0);
          setEuropaReds(0);
          setEuropaYellows(0);
          setEuropeImage(0);

          setLeague2("Ingen data");
        } else {
          setEuropaMatches(
            data.response[0].statistics[2].games.appearences + 0
          );
          setEuropeTeam(data.response[0].statistics[2].team.name);
          setEuropaSaves(data.response[0].statistics[2].goals.saves + 0);
          setEuropaConceded(data.response[0].statistics[2].goals.conceded + 0);
          setEuropaMinutes(data.response[0].statistics[2].games.minutes + 0);
          setEuropaReds(data.response[0].statistics[2].cards.red + 0);
          setEuropaYellows(data.response[0].statistics[2].cards.yellow + 0);
          setEuropeImage(data.response[0].statistics[2].league.logo);
          setLeague2(data.response[0].statistics[2].league.name);
        }

        if (data.response[0].statistics[3] == undefined) {
          setFriendliesTeam("");
          setFriendliesMatches(0);
          setFriendliesSaves(0);
          setFriendliesConceded(0);
          setFriendliesMinutes(0);
          setFriendliesReds(0);
          setFriendliesYellows(0);

          setLeague3("Ingen data");
        } else {
          setFriendliesMatches(
            data.response[0].statistics[3].games.appearences + 0
          );
          setFriendliesTeam(data.response[0].statistics[3].team.name);
          setFriendliesSaves(data.response[0].statistics[3].goals.saves + 0);
          setFriendliesConceded(
            data.response[0].statistics[3].goals.conceded + 0
          );
          setFriendliesMinutes(
            data.response[0].statistics[3].games.minutes + 0
          );
          setFriendliesReds(data.response[0].statistics[3].cards.red + 0);
          setFriendliesYellows(data.response[0].statistics[3].cards.yellow + 0);
          setLeague3(data.response[0].statistics[3].league.name);
        }

        if (data.response[0].statistics[4] == undefined) {
          setNationalsTeam("");
          setNationalsMatches(0);
          setNationalsSaves(0);
          setNationalsConceded(0);
          setNationalsMinutes(0);
          setNationalsReds(0);
          setNationalsYellows(0);
          setLeague4("Ingen data");
        } else {
          setNationalsMatches(
            data.response[0].statistics[4].games.appearences + 0
          );
          setNationalsTeam(data.response[0].statistics[4].team.name);
          setNationalsSaves(data.response[0].statistics[4].goals.saves + 0);
          setNationalsConceded(
            data.response[0].statistics[4].goals.conceded + 0
          );
          setNationalsMinutes(data.response[0].statistics[4].games.minutes + 0);
          setNationalsReds(data.response[0].statistics[4].cards.red + 0);
          setNationalsYellows(data.response[0].statistics[4].cards.yellow + 0);
          setLeague4(data.response[0].statistics[4].league.name);
        }

        if (data.response[0].statistics[5] == undefined) {
          setLeague5Team("");
          setLeague5Matches(0);
          setLeague5Saves(0);
          setLeague5Conceded(0);
          setLeague5Minutes(0);
          setLeague5Reds(0);
          setLeague5Yellows(0);
          setLeague5("Ingen data");
        } else {
          setLeague5Matches(
            data.response[0].statistics[5].games.appearences + 0
          );
          setLeague5Team(data.response[0].statistics[5].team.name);
          setLeague5Saves(data.response[0].statistics[5].goals.saves + 0);
          setLeague5Conceded(data.response[0].statistics[5].goals.conceded + 0);
          setLeague5Minutes(data.response[0].statistics[5].games.minutes + 0);
          setLeague5Reds(data.response[0].statistics[5].cards.red + 0);
          setLeague5Yellows(data.response[0].statistics[5].cards.yellow + 0);
          setLeague5(data.response[0].statistics[5].league.name);
        }

        if (data.response[0].statistics[6] == undefined) {
          setLeague6Team("");
          setLeague6Matches(0);
          setLeague6Saves(0);
          setLeague6Conceded(0);
          setLeague6Minutes(0);
          setLeague6Reds(0);
          setLeague6Yellows(0);
          setLeague6("Ingen data");
        } else {
          setLeague6Matches(
            data.response[0].statistics[6].games.appearences + 0
          );
          setLeague6Team(data.response[0].statistics[6].team.name);
          setLeague6Saves(data.response[0].statistics[6].goals.saves + 0);
          setLeague6Conceded(data.response[0].statistics[6].goals.conceded + 0);
          setLeague6Minutes(data.response[0].statistics[6].games.minutes + 0);
          setLeague6Reds(data.response[0].statistics[6].cards.red + 0);
          setLeague6Yellows(data.response[0].statistics[6].cards.yellow + 0);
          setLeague6(data.response[0].statistics[6].league.name);
        }

        setMainLeague(data.response[0].statistics[0].league.name);
      })
      .catch(function (error) {
        console.error(error);
      });

    const seasons = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/seasons",
      params: { player: player },
    };

    axios
      .request(seasons)
      .then(function (response) {
        setSeasons(response.data.response.reverse());
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [selectedSeason]);

  useEffect(() => {
    const options = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/players",
      params: { id: player, season: selectedSvenskaCupenYear },
    };

    axios
      .request(options)
      .then(function (response) {
        const data = response.data;
        const stats = data.response[0].statistics;
        const svenskaCupenStat = stats.find(
          (stats) => stats.league.name === "Svenska Cupen"
        );
        setSvenskaCupenData(svenskaCupenStat);
        setLoaded4(true);
      })

      .catch(function (error) {
        console.error(error);
      });
  }, [selectedSeason]);

  useEffect(() => {
    const options3 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/trophies",
      params: { player: player },
    };

    axios
      .request(options3)
      .then(function (response) {
        setGetTrophies(response.data.response);
      })
      .catch(function (error) {
        console.error(error);
      });

    const transfers = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/transfers",
      params: { player: player },
    };

    axios
      .request(transfers)
      .then(function (response) {
        setTransfers(response.data.response[0].transfers);
      })
      .catch(function (error) {
        console.error(error);
      });

    const numbers = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/numbers",
      params: { player: player },
    };
    axios
      .request(numbers)
      .then(function (response) {
        // console.log(response.data);
        setJerseyNumber(response.data.response[0].players[0].number);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  // Leftside data

  if (loaded === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  let rating = 0;
  if (playersData.statistics[0].games.rating === null) {
    rating = 0;
  } else {
    rating = playersData.statistics[0].games.rating.slice(0, 4);
  }
  const allsvenskanImage = playersData.statistics[0].league.logo;
  const name = playersData.player.name;
  const team = playersData.statistics[0].team.name;
  const matches = playersData.statistics[0].games.appearences + 0;
  const minutes = playersData.statistics[0].games.minutes + 0;
  const substituteIn = playersData.statistics[0].substitutes.in + 0;
  const substituteOut = playersData.statistics[0].substitutes.out + 0;
  const goalsConceded = playersData.statistics[0].goals.conceded + 0;
  const goalsSaved = playersData.statistics[0].goals.saves + 0;
  const yellow = playersData.statistics[0].cards.yellow + 0;
  const red = playersData.statistics[0].cards.red + 0;
  const position = playersData.statistics[0].games.position;
  const penaltyGoals = playersData.statistics[0].penalty.scored + 0;
  const missedPenalties = playersData.statistics[0].penalty.scored + 0;
  const causedPenalties = playersData.statistics[0].penalty.commited + 0;
  const penaltiesSaved = playersData.statistics[0].penalty.saved + 0;
  const totalPasses = playersData.statistics[0].passes.total + 0;
  const keyPasses = playersData.statistics[0].passes.key + 0;
  //const passingAccuracy = playersData.statistics[0].passes.accuracy + 0;
  const blocks = playersData.statistics[0].tackles.blocks + 0;
  const interceptions = playersData.statistics[0].tackles.interceptions + 0;
  const wonDuels = playersData.statistics[0].duels.won + 0;
  const totalDuels = playersData.statistics[0].duels.total + 0;
  const fouls = playersData.statistics[0].fouls.committed + 0;
  const receivedFouls = playersData.statistics[0].fouls.drawn + 0;
  const shots = playersData.statistics[0].shots.total + 0;
  const shotsOn = playersData.statistics[0].shots.on + 0;
  const dribbles = playersData.statistics[0].dribbles.attempts + 0;
  const successDribbles = playersData.statistics[0].dribbles.success + 0;

  //rightside data

  const image = playersData.player.photo;
  const firstName = playersData.player.firstname;
  const lastName = playersData.player.lastname;
  const nationality = playersData.player.nationality;
  const birthday = playersData.player.birth.date;
  const birthCity = playersData.player.birth.place;
  const age = playersData.player.age;
  const height = playersData.player.height;
  const weight = playersData.player.weight;
  const skadad = playersData.player.injured;

  // svenska cupen data

  const svenskaCupenLeagueName =
    svenskaCupenData && svenskaCupenData.league && svenskaCupenData.league.name;
  const svenskaCupenTeam =
    svenskaCupenData && svenskaCupenData.team && svenskaCupenData.team.name;
  const svenskaCupenMatches =
    (svenskaCupenData &&
      svenskaCupenData.games &&
      svenskaCupenData.games.appearences) ||
    0;
  const svenskaCupenSaves =
    (svenskaCupenData &&
      svenskaCupenData.goals &&
      svenskaCupenData.goals.saves) ||
    0;
  const svenskaCupenConceded =
    (svenskaCupenData &&
      svenskaCupenData.goals &&
      svenskaCupenData.goals.conceded) ||
    0;
  const svenskaCupenMinutes =
    (svenskaCupenData &&
      svenskaCupenData.games &&
      svenskaCupenData.games.minutes) ||
    0;
  const svenskaCupenReds =
    (svenskaCupenData &&
      svenskaCupenData.cards &&
      svenskaCupenData.cards.red) ||
    0;
  const svenskaCupenYellows =
    (svenskaCupenData &&
      svenskaCupenData.cards &&
      svenskaCupenData.cards.yellow) ||
    0;
  const svenskaCupenSeason =
    svenskaCupenData &&
    svenskaCupenData.league &&
    svenskaCupenData.league.season;

  //rightside bottom data

  if (loaded === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div className={styles.parent}>
      <Helmet>
        <title>
          {" "}
          {firstName} {lastName} - Statistik & Fakta | AIKstatistik.se
        </title>
      </Helmet>
      <div className={styles.leftSide}>
        <Navbar />
        <h1 className={styles.playerName}> {name}</h1>
        <h2 className={styles.signingTitleMobile}>
          {(() => {
            if (jerseyNumber === 0) {
              return null;
            } else {
              return jerseyNumber;
            }
          })()}{" "}
        </h2>
        <img
          className={styles.playerImage}
          width="300px"
          height="300px"
          src={image}
          alt="Player image"
        ></img>
        <h3 className={styles.bigMenu}>
          {mainLeague}{" "}
          <img
            className={styles.cupLogo}
            src={allsvenskanImage}
            alt="League logo"
          ></img>
        </h3>
        <p className={styles.position}>
          {(() => {
            if (team === "AIK stockholm") {
              return "AIK";
            } else if (team === "AIK Stockholm") {
              return "AIK";
            } else {
              return team;
            }
          })()}{" "}
        </p>
        <p className={styles.season}>
          Säsong:{" "}
          <select
            className={styles.customSelect}
            onChange={(e) => setSelectedSeason(e.target.value)}
          >
            {seasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>{" "}
        </p>
        <p className={styles.position}>
          Roll:{" "}
          {(() => {
            if (position === "Goalkeeper") {
              return "Målvakt";
            } else if (position === "goalkeeper") {
              return "Målvakt";
            } else {
              return position;
            }
          })()}
        </p>
        <p className={styles.position}>
          Status:{" "}
          {(() => {
            if (skadad === false) {
              return (
                <span key={crypto.randomUUID()} style={{ color: "#ffdd00" }}>
                  Frisk
                </span>
              );
            } else if (skadad === true) {
              return (
                <span key={crypto.randomUUID()} style={{ color: "#D70040" }}>
                  Skadad
                </span>
              );
            }
          })()}
        </p>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.matches}>Matcher: {matches}</p>
          <p className={styles.minutes}>Minuter: {minutes}</p>
        </div>
        <div className={styles.flexbox}>
          <p className={styles.minutes}>Inbytt: {substituteIn}</p>
          <p className={styles.minutes}>Utbytt: {substituteOut}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Betyg: {rating}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Räddningar: {goalsSaved}</p>
          <p className={styles.goals}>Insläppta: {goalsConceded}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p>Röda: {red}</p>
          <p>Gula: {yellow}</p>
        </div>

        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Passningar</h3>
        <div className={styles.flexbox}>
          <p>Totala: {totalPasses}</p>
          <p>Nyckelpassningar: {keyPasses}</p>
        </div>

        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Tacklingar</h3>
        <div className={styles.flexbox}>
          <p>Blockar: {blocks}</p>
          <p>Brytningar: {interceptions}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Vunna dueller: {wonDuels}</p>
          <p>Totala: {totalDuels}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Regelbrott</h3>
        <div className={styles.flexbox}>
          <p>Orsakade: {fouls}</p>
          <p>Mottagna: {receivedFouls}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Skott</h3>
        <div className={styles.flexbox}>
          <p>Totala: {shots}</p>
          <p>På mål: {shotsOn}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Dribblingar</h3>
        <div className={styles.flexbox}>
          <p>Försök: {dribbles}</p>
          <p>Lyckade: {successDribbles}</p>
        </div>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.playerImage}
            width="260px"
            height="260px"
            src={image}
            alt="Player image"
          ></img>
          <div className={styles.nameBox}>
            {" "}
            <h3 className={styles.playerNameBig}>
              {" "}
              {firstName}
              <br></br>
              {lastName}
              <br></br>{" "}
              <span className={styles.bigNumber}>
                {(() => {
                  if (jerseyNumber === 0) {
                    return "";
                  } else if (jerseyNumber === null) {
                    return "";
                  } else {
                    return jerseyNumber;
                  }
                })()}
              </span>
            </h3>
          </div>
        </div>
        <div className={styles.compInfo}>
          {svenskaCupenLeagueName && (
            <div className={styles.league6Box}>
              <h2 className={styles.cupTitle}>{svenskaCupenLeagueName} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (svenskaCupenTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (svenskaCupenTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return svenskaCupenTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {svenskaCupenMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Räddningar: {svenskaCupenSaves}</h3>
              <div className={styles.borderBox}></div>
              <h3>Insläppta: {svenskaCupenConceded}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {svenskaCupenMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {svenskaCupenReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {svenskaCupenYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league == "Ingen data" || league === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.swedishCupBox}>
              <h2 className={styles.cupTitle}>{league} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (swedishTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (swedishTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return swedishTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {swedishCupMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Räddningar: {swedishCupSaves}</h3>
              <div className={styles.borderBox}></div>
              <h3>Insläppta: {swedishCupConceded}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {swedishCupMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {swedishCupReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {swedishCupYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league2 == "Ingen data" || league2 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.europeBox}>
              <h2 className={styles.cupTitle}>{league2} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (europeTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (europeTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return europeTeam;
                  }
                })()}{" "}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {europaMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Räddningar: {europaSaves}</h3>
              <div className={styles.borderBox}></div>
              <h3>Insläppta: {europaConceded}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {europaMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {europaReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {europaYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league3 == "Ingen data" || league3 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.friendliesBox}>
              <h2 className={styles.cupTitle}>{league3} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (friendliesTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (friendliesTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return friendliesTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {friendliesMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Räddningar: {friendliesSaves}</h3>
              <div className={styles.borderBox}></div>
              <h3>Insläppta: {friendliesConceded}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {friendliesMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {friendliesReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {friendliesYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league4 == "Ingen data" || league4 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.nationalsBox}>
              <h2 className={styles.cupTitle}>{league4} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (nationalsTeam === "AIK stockholm") {
                    return "AIK";
                  } else if (nationalsTeam === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return nationalsTeam;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {nationalsMatches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Räddningar: {nationalsSaves}</h3>
              <div className={styles.borderBox}></div>
              <h3>Insläppta: {nationalsConceded}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {nationalsMinutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {nationalsReds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {nationalsYellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league5 == "Ingen data" || league5 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.league5Box}>
              <h2 className={styles.cupTitle}>{league5} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (league5Team === "AIK stockholm") {
                    return "AIK";
                  } else if (league5Team === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return league5Team;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {league5Matches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Räddningar: {league5Saves}</h3>
              <div className={styles.borderBox}></div>
              <h3>Insläppta: {league5Conceded}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {league5Minutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {league5Reds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {league5Yellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          {league6 == "Ingen data" || league6 === "Svenska Cupen" ? (
            <div style={{ display: "none" }}></div>
          ) : (
            <div className={styles.league6Box}>
              <h2 className={styles.cupTitle}>{league6} </h2>
              <div className={styles.borderBox}></div>
              <h3>
                {" "}
                {(() => {
                  if (league6Team === "AIK stockholm") {
                    return "AIK";
                  } else if (league6Team === "AIK Stockholm") {
                    return "AIK";
                  } else {
                    return league6Team;
                  }
                })()}
              </h3>
              <div className={styles.borderBox}></div>
              <h3>Matcher: {league6Matches}</h3>
              <div className={styles.borderBox}></div>
              <h3>Räddningar: {league6Saves}</h3>
              <div className={styles.borderBox}></div>
              <h3>Insläppta: {league6Conceded}</h3>
              <div className={styles.borderBox}></div>
              <h3>Minuter: {league6Minutes}</h3>
              <div className={styles.borderBox}></div>
              <h3>Röda: {league6Reds}</h3>
              <div className={styles.borderBox}></div>
              <h3>Gula: {league6Yellows}</h3>
              <div className={styles.borderBoxLast}></div>
            </div>
          )}
          <div id="ezoic-pub-ad-placeholder-101"> </div>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>

        <h1 className={styles.factBox}>Faktaruta</h1>
        <p className={styles.nationality}>
          Namn: {firstName} {lastName}
        </p>
        <p className={styles.nationality}>Nationalitet: {nationality}</p>

        <p>Födelsedag: {birthday}</p>
        <p>Födelsestad: {birthCity}</p>
        <p>Ålder: {age}</p>
        <p>Längd: {height}</p>
        <p>Vikt: {weight}</p>
        <div className={styles.border}></div>
        <h1 className={styles.factBox}>Placeringar</h1>
        {getTrophies.map((trophy) => {
          return (
            <p key={crypto.randomUUID()} className={styles.titleP}>
              {trophy.season} - {trophy.league} - {trophy.place}
            </p>
          );
        })}
        <div className={styles.border}></div>
        <h1 className={styles.factBox}>Övergångar</h1>
        {transfers.map((transfer) => {
          return (
            <p key={crypto.randomUUID()} className={styles.titleP}>
              {transfer.date} - Till{" "}
              {(() => {
                if (transfer.teams.in.name === "AIK stockholm") {
                  return "AIK";
                } else if (transfer.teams.in.name === "AIK Stockholm") {
                  return "AIK";
                } else {
                  return transfer.teams.in.name;
                }
              })()}{" "}
              Från{" "}
              {(() => {
                if (transfer.teams.out.name === "AIK stockholm") {
                  return "AIK";
                } else if (transfer.teams.out.name === "AIK Stockholm") {
                  return "AIK";
                } else {
                  return transfer.teams.out.name;
                }
              })()}
              - {transfer.type}
            </p>
          );
        })}
        <div className={styles.border}></div>
      </div>

      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default PlayerIdGoalie;
