import React from 'react';

const TableWidget = () => {
  return (
    <div>
      <iframe
        src="/table-widget.html"
        title="Table Widget"
        style={{ border: 'none', width: '100%', minHeight: '240px' }}
      />
    </div>
  );
};

export default TableWidget;