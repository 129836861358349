import React from 'react'
import PlayerId from '../../components/PlayerId';


function ViktorFischer() {

   const player = "1294";

   const initialYear = "2023"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default ViktorFischer
