import React from 'react'
import styles from "../css/Footer.module.css"
import {AiOutlineTwitter} from "react-icons/ai"

function Footer() {
  const handleClick = () => {
    window.open("https://twitter.com/AIKstat");
  };
  return (
    <div className={styles.parentDiv}>
      <div className={styles.middle}>
        <h1>AIK Statistik</h1>
        <p className={styles.catchPhrase}>Den ultimata källan för AIK fotbollsspelarstatistik</p>
        <div className={styles.borderBox}></div>
      </div>
      <div className={styles.right}>
 <p className={styles.mail}>info@AIKstatistik.se</p>

   
   <a className={styles.twitter} onClick={handleClick} href="#"> <AiOutlineTwitter /></a>
    <p className={styles.powered}>
    Denna webbsida är inte en officiell AIK-sida.
    </p>
    <p className={styles.powered}>
    Copyright © 2023 AIKstatistik.se
    </p>
   
    </div>

    </div>
  )
}

export default Footer