import React from 'react'
import PlayerId from '../../components/PlayerId';


function LionelMessi() {

   const player = "154";
   const initialYear = "2022"

  return (
    <>
    <PlayerId data={player} data2={initialYear}/>
    </>
  )
}

export default LionelMessi