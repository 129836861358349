import React from 'react'
import PlayerIdGoalie from '../../components/PlayerIdGoalie';


function Diawara() {

   const player = "47660";
  const initialYear = "2024"

  return (
    <>
    <PlayerIdGoalie data={player} data2={initialYear}/>
    </>
  )
}

export default Diawara
