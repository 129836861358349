import React from 'react'
import PlayerId from '../../components/PlayerId';


function Kouakou() {

   const player = "47656";
   const initialYear = "2023"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default Kouakou