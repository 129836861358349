import React from 'react'
import PlayerId from '../../components/PlayerId';


function Aviander() {

   const player = "388804";
   const initialYear = "2024"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }
export default Aviander
