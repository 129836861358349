import React from 'react'
import PlayerId from '../../components/PlayerId';


function AxelBjornstrom() {

   const player = "48266";
   const initialYear = "2024"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }
export default AxelBjornstrom
