import React from "react";
import styles from "../css/DerbyStatistik.module.css";

function DerbyTabell() {
  return (
    <div>
      <h1 className={styles.factBox}>Derbytabellen</h1>
      <h1 className={styles.factBox} style={{ marginTop: "0" }}>
        2023
      </h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.headerCell}></th>
            <th className={styles.headerCell}></th>
            <th
              className={`${styles.headerCell} ${styles.cellSpacing}`}
              title="Spelade"
            >
              S
            </th>
            <th
              className={`${styles.headerCell} ${styles.cellSpacing}`}
              title="Vunna"
            >
              V
            </th>
            <th
              className={`${styles.headerCell} ${styles.cellSpacing}`}
              title="Oavgjorda"
            >
              O
            </th>
            <th
              className={`${styles.headerCell} ${styles.cellSpacing}`}
              title="Förlorade"
            >
              F
            </th>
            <th
              className={`${styles.headerCell} ${styles.cellSpacing}`}
              title="Gjorda Mål"
            >
              M
            </th>
            <th
              className={`${styles.headerCell} ${styles.cellSpacing}`}
              title="Insläppta Mål"
            >
              I
            </th>
            <th
              className={`${styles.headerCell} ${styles.cellSpacing}`}
              title="Poäng"
            >
              P
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key={crypto.randomUUID()} className={styles.h2hP}>
            <td className={styles.rankCell}>1.</td>
            <td className={`${styles.tableTeam} ${styles.cellSpacing}`}>
              <span className={styles.tableTeam}>Bajen</span>
            </td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>4</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>2</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>1</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>1</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>8</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>7</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>7</td>
          </tr>
          <tr key={crypto.randomUUID()} className={styles.h2hP}>
            <td className={styles.rankCell}>2.</td>
            <td className={`${styles.tableTeam} ${styles.cellSpacing}`}>
              <span className={styles.tableTeam}>AIK</span>
            </td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>4</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>2</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>0</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>2</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>6</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>5</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>6</td>
          </tr>
          <tr key={crypto.randomUUID()} className={styles.h2hP}>
            <td className={styles.rankCell}>3.</td>
            <td className={`${styles.tableTeam} ${styles.cellSpacing}`}>
              <span className={styles.tableTeam}>DIF</span>
            </td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>4</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>1</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>1</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>2</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>4</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>6</td>
            <td className={`${styles.tableResult} ${styles.cellSpacing}`}>4</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DerbyTabell;
