import React from 'react'
import PlayerId from '../../components/PlayerId';


function Vaisanen() {

   const player = "30987";

   const initialYear = "2023"

   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default Vaisanen