import React from 'react'
import PlayerId from '../../components/PlayerId';


function Otieno() {

   const player = "3187";
   const initialYear = "2024"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default Otieno
