import React from 'react'
import PlayerId from '../../components/PlayerId';


function AbdulFuseini() {

   const player = "392111";

   const initialYear = "2022"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default AbdulFuseini