import React from 'react'
import PlayerId from '../../components/PlayerId';


function BersantCelina() {

   const player = "19334";
   const initialYear = "2024"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }
export default BersantCelina