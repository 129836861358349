import React from "react";

function MatchForm(props) {
  const data = props.data.split("");

  const mappedData = data.map((result) => {
    if (result === "W") {
      return <span  key={crypto.randomUUID()} style={{ color: "#ffdd00" }}>W</span>;
    }
    if (result === "L") {
      return <span  key={crypto.randomUUID()} style={{ color: "#D70040" }}>L</span>;
    }
    if (result === "D") {
      return <span  key={crypto.randomUUID()} style={{ color: "white" }}>D</span>;
    }
  });

  return <p className={props.className}>Form: {mappedData}</p>;
}

export default MatchForm;
