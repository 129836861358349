import React from "react";
import styles from "../css/Sitemap.module.css";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/AIKs.webp";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

function Sitemap() {
  return (
    <div className={styles.parent}>
      <div className={styles.leftSide}>
        <h1 className={styles.playerName}>
          {" "}
          <Navbar />{" "}
        </h1>
        <img
          className={styles.logoImageOmOss}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>

        <h1 className={styles.aikStats}>Sitemap</h1>

        <div className={styles.dataBox}>
          <p className={styles.dataText}>
            Detta är en HTML sitemap ifall du har svårigheter att navigera på
            webbsidan. Samtliga länkar på webbsidan finns här.
          </p>
          <div className={styles.border}></div>
        </div>
        <h4 className={styles.links}>
          <Link className={styles.links} to="/">
            Startsida
          </Link>
        </h4>

        <div className={styles.border}></div>
        <h4 className={styles.links}>Målvater</h4>
        <Link className={styles.smallLink } to="/Janosevic">
            B. Janosevic
          </Link>
        <Link className={styles.smallLink } to="/Nordfeldt">
            K. Nordfeldt
          </Link>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Backar</h4>
        <Link className={styles.smallLink } to="/AxelBjornstrom">
            A. Bjornstrom
          </Link>
          <Link className={styles.smallLink } to="/Faqa">
            A. Faqa
          </Link>
          <Link className={styles.smallLink } to="/Haliti">
            J. Haliti
          </Link>
          <Link className={styles.smallLink } to="/Milosevic">
            A. Milosevic
          </Link>
          <Link className={styles.smallLink } to="/Modesto">
           R. Modesto
          </Link>
          <Link className={styles.smallLink } to="/otieno">
            E. Otieno
          </Link>
          <Link className={styles.smallLink } to="/papagiannopoulos">
            S. Papagiannopoulos
          </Link>
          <Link className={styles.smallLink } to="/shichenje">
            C. Shichenje
          </Link>
          <Link className={styles.smallLink } to="/tihi">
            R. Tihi
          </Link>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Mittfältare</h4>
        <Link className={styles.smallLink } to="/abdihakin">
            A. Ali
          </Link>
          <Link className={styles.smallLink } to="/aviander">
            H. Aviander
          </Link>
          <Link className={styles.smallLink } to="/victorAndersson">
            V. Andersson
          </Link>
          <Link className={styles.smallLink } to="/tahaAyari">
            T. Ayari
          </Link>
          <Link className={styles.smallLink } to="/ceesay">
            J. Ceesay
          </Link>
          <Link className={styles.smallLink } to="/eliasDurmaz">
            E. Durmaz
          </Link>
          <Link className={styles.smallLink } to="/jimmyDurmaz">
            J. Durmaz
          </Link>
          <Link className={styles.smallLink } to="/lamineDabo">
           L. Dabo
          </Link>
          <Link className={styles.smallLink } to="/elbouzedi">
            Z. Elbouzedi
          </Link>
          <Link className={styles.smallLink } to="/bilal">
            B. Hussein
          </Link>
          <Link className={styles.smallLink } to="/keita">
            A. Keita
          </Link>
          <Link className={styles.smallLink } to="/magashy">
            A. Magashy
          </Link>
          <Link className={styles.smallLink } to="/thill">
            V. Thill
          </Link>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Anfallare</h4>
        <Link className={styles.smallLink } to="/faraj">
            O. Faraj
          </Link>
          <Link className={styles.smallLink } to="/fesshaie">
            A. Fesshaie
          </Link>
          <Link className={styles.smallLink } to="/viktorFischer">
            V. Fischer
          </Link>
          <Link className={styles.smallLink } to="/guidetti">
            J. Guidetti
          </Link>
          <Link className={styles.smallLink } to="/kabuye">
            K. Kabuye
          </Link>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.emblemImage}
            src={logo}
            height="250px"
            width="250px"
            alt="AIK logo"
          />
          <h1 className={styles.aikStats}>Sitemap</h1>
          <h1 className={styles.info}>Info</h1>

          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
            Detta är en HTML sitemap ifall du har svårigheter att navigera på
            webbsidan. Samtliga länkar på webbsidan finns här.
          </h6>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>
        <h4 className={styles.links}>Utlåningar</h4>
        <Link className={styles.smallLink } to="/Bonde">
            R. Bonde
          </Link>
        <Link className={styles.smallLink } to="/brolin">
            S. Brolin
          </Link>
          <Link className={styles.smallLink } to="/meja">
            H. Meja
          </Link>
          <Link className={styles.smallLink } to="/kimpioka">
          B. Kimpioka
          </Link>
          <Link className={styles.smallLink } to="/ring">
            E. Ring
          </Link>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Rykten in</h4>
        <Link className={styles.smallLink } to="/kwakuKariKari">
            K. Karikari
          </Link>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Exporter</h4>
        <Link className={styles.smallLink } to="/abraham">
            P. Abraham
          </Link>
          <Link className={styles.smallLink } to="/aaa">
            A. Ahmed Fatah
          </Link>
          <Link className={styles.smallLink } to="/yasinAyari">
            Y. Ayari
          </Link>
          <Link className={styles.smallLink } to="/bahoui">
            N. Bahoui
          </Link>
          <Link className={styles.smallLink } to="/carlgren">
            P. Carlgren
          </Link>
          <Link className={styles.smallLink } to="/eliasson">
            N. Eliasson
          </Link>
          <Link className={styles.smallLink } to="/forsberg">
            L. Forsberg
          </Link>
          <Link className={styles.smallLink } to="/gravius">
            C. Gravius
          </Link>
          <Link className={styles.smallLink } to="/isak">
            A. Isak
          </Link>
          <Link className={styles.smallLink } to="/robinJansson">
            R. Jansson
          </Link>
          <Link className={styles.smallLink } to="/kahl">
            E. Kahl
          </Link>
          <Link className={styles.smallLink } to="/kouakou">
            C. Kouakou
          </Link>
          <Link className={styles.smallLink } to="/lahne">
            J. Lahne
          </Link>
          <Link className={styles.smallLink } to="/jordanLarsson">
            J. Larsson
          </Link>
          <Link className={styles.smallLink } to="/kristofferOlsson">
            K. Olsson
          </Link>
          <Link className={styles.smallLink } to="/quaison">
            R. Quaison
          </Link>
          <Link className={styles.smallLink } to="/saletros">
            A. Saletros
          </Link>
          <Link className={styles.smallLink } to="/stefanelli">
            N. Stefanelli
          </Link>
          <Link className={styles.smallLink } to="/strandberg">
            C. Strandberg
          </Link>
          <Link className={styles.smallLink } to="/strannegard">
            T. Strannegård
          </Link>
          <Link className={styles.smallLink } to="/danielSundgren">
            D. Sundgren
          </Link>
          <Link className={styles.smallLink } to="/vaisanen">
            S. Väisanen
          </Link>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Övrigt</h4>
        <Link className={styles.smallLink } to="/omOss">
            Om oss
          </Link>
          <Link className={styles.smallLink } to="/derbyStatistik">
            Stockholmsderbyn
          </Link>
          <Link className={styles.smallLink } to="/aikHighlights">
            Filmer
          </Link>
          <Link className={styles.smallLink } to="/fvsW">
            F vs W
          </Link>
          <Link className={styles.smallLink } to="/vs">
            Jämför spelare
          </Link>
          <Link className={styles.smallLink } to="/aikSolnaFk">
            AIK Solna FK
          </Link>
        <div className={styles.border}></div>
      </div>
      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default Sitemap;
