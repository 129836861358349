import React from "react";
import styles from "../css/PageNotFound.module.css";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/pensive.png";
import Footer from "../components/Footer";

function PageNotFound() {
  const handleClick7 = () => {
    window.open("https://stportfolio.netlify.app/");
  };
  return (
    <div className={styles.parent}>
      <div className={styles.leftSide}>
        <h1 className={styles.playerName}>
          {" "}
          <Navbar />{" "}
        </h1>
        <img
          className={styles.logoImageOmOss}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>

        <h1 className={styles.aikStats}>Sidan saknas</h1>

        <div className={styles.dataBox}>
          <p className={styles.dataText}>
            Jag är tydligen en amatör och har lagt in en trasig länk på webbsidan, länken existerar tyvärr inte.
          </p>
          <div className={styles.border}></div>
        </div>
        <h4 className={styles.links}>Kontakt</h4>
        <a className={styles.smallLink} onClick={handleClick7} href="">
          Portfolio
        </a>
        <p className={styles.contact}>info@AIKstatistik.se</p>

        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.emblemImage}
            src={logo}
            height="250px"
            width="250px"
            alt="AIK logo"
          />
          <h1 className={styles.aikStats}>Sidan saknas</h1>
          <h1 className={styles.info}>Info</h1>

          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
          Jag är tydligen en amatör och har lagt in en trasig länk på webbsidan, länken existerar tyvärr inte. 404 Error!
          </h6>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>
      </div>
      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default PageNotFound;
