import React, { useState, useEffect} from "react";
import styles from "../css/AikHighlights.module.css";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/AIKs.webp";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import YouTube from "react-youtube";

function AikHighlights() {
  const [AIKupdates, setAIKupdates] = useState([]);
  const [AIKfotboll, setAIKfotboll] = useState([]);
  const [opts, setOpts] = useState({
    height: "150",
    width: "250",
  });

  useEffect(() => {
    const highlights = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/highlights",
    };

    axios
      .request(highlights)
      .then(function (response) {
        setAIKupdates(response.data.items);
      })
      .catch(function (error) {
        console.error(error);
      });
    const highlights2 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/highlights2",
    };

    axios
      .request(highlights2)
      .then(function (response) {
        setAIKfotboll(response.data.items);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;

      if (windowWidth < 500) {
        setOpts({
          height: "200",
          width: "300",
        });
      } else if (windowWidth < 1600) {
        setOpts({
          height: "150",
          width: "250",
        }); } else {
        setOpts({
          height: "150",
          width: "250",
        });
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className={styles.parent}>
      <Helmet>
        <title>
          AIKstatistik.se - Höjdpunkter, highlights, intervjuer, Youtube | AIK
          fotboll
        </title>
      </Helmet>
      <div className={styles.leftSide}>
        <h1 className={styles.playerName}>
          {" "}
          <Navbar />{" "}
        </h1>
        <img
          className={styles.logoImageOmOss}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>

        <h1 className={styles.aikStats}>AIK filmer</h1>

        <div className={styles.dataBox}>
          <p className={styles.dataText}>
            Här på den här delen av webbsidan kan du se videor från YouTube som
            handlar om AIK fotboll. Vi har samlat höjdpunkter från AIK:s matcher
            samt intervjuer med spelare och tränare från några av de bästa
            YouTube-kanalerna för fotbollsrelaterat innehåll.
            <br></br>
            <br></br>
            Följ med på AIK:s resa genom säsongen och upplev deras bästa
            ögonblick på planen. Se höjdpunkter från matcher, mål och
            räddningar, intervjuer och massa annat.
            <br></br>
            <br></br>
            Filmerna hämtas ut automatiskt med hjälp av Youtube data API så du
            kan vara säker på att det senaste från dessa kanaler finns här. Har
            du någon ide på en annan kanal eller driver du en egen Youtube-kanal
            med AIK fotboll relaterat innehåll så tveka inte att höra av dig
            till mig så kan jag publicera det här.
            <br></br>
            <br></br>
            Så slappna av och njut av några av de bästa videorna om AIK fotboll
            som YouTube har att erbjuda.
          </p>
          <div className={styles.border}></div>
        </div>
        <h4 className={styles.links}>AIK Updates</h4>
        <p className={styles.youtubeBread}>
          AIK Updates är en populär YouTube-kanal som fokuserar på att leverera
          högkvalitativt innehåll om AIK fotboll. Kanalen erbjuder regelbundet
          höjdpunkter och annat smått och gott från AIK:s matcher bland annat.
          Med en professionell produktion och som alltid strävar efter att
          leverera det bästa innehållet, är AIK Updates definitivt en kanal som
          är värd att följa för alla fotbollsentusiaster, särskilt för AIK
          supportrar.
        </p>
        <div className={styles.border}></div>
        {AIKupdates.map((item) => {
          return (
            <div key={item.id.videoId} className={styles.youtubeDiv}>
              <a
                href={`https://www.youtube.com/watch?v=${item.id.videoId}`}
                target="_blank"
                className={styles.itemLink}
              >
                <h5 className={styles.youtubeTitle}>
                  {item.snippet.title.replace("&amp;", "")}
                </h5>
              </a>
              <YouTube
        videoId={item.id.videoId}
        className={styles.youTubeImg}
        src={item.snippet.thumbnails.medium.url}
        alt="AIK highlight"
        opts={opts}
      />
            </div>
          );
        })}
        <div className={styles.border}></div>

        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.emblemImage}
            src={logo}
            height="250px"
            width="250px"
            alt="AIK logo"
          />
          <h1 className={styles.aikStats}>AIK filmer</h1>
          <h1 className={styles.info}>Info</h1>

          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
            Här på den här delen av webbsidan kan du se videor från YouTube som
            handlar om AIK fotboll. Vi har samlat höjdpunkter från AIK:s matcher
            samt intervjuer med spelare och tränare från några av de bästa
            YouTube-kanalerna för fotbollsrelaterat innehåll.
            <br></br>
            <br></br>
            Följ med på AIK:s resa genom säsongen och upplev deras bästa
            ögonblick på planen. Se höjdpunkter från matcher, mål och
            räddningar, intervjuer och massa annat.
            <br></br>
            <br></br>
            Filmerna hämtas ut automatiskt med hjälp av Youtube data API så du
            kan vara säker på att det senaste från dessa kanaler finns här. Har
            du någon ide på en annan kanal eller driver du en egen Youtube-kanal
            med AIK fotboll relaterat innehåll så tveka inte att höra av dig
            till mig så kan jag publicera det här.
            <br></br>
            <br></br>
            Så slappna av och njut av några av de bästa videorna om AIK fotboll
            som YouTube har att erbjuda.
          </h6>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>
        <h4 className={styles.links}>AIK Fotboll</h4>
        <p className={styles.youtubeBread}>
          AIK Fotbolls egen officiella Youtube-kanal. Kanalen erbjuder en mängd
          olika videor, inklusive, intervjuer med spelare och tränare, bakom
          kulisserna-innehåll och mycket mer. Med högkvalitativa produktioner
          och en stark närvaro på plattformen är AIK Fotbolls YouTube-kanal ett
          utmärkt sätt för supportrar att följa laget och få lite extra innehåll
          kring laget.
        </p>
        <div className={styles.border}></div>
        {AIKfotboll.map((item) => {
          return (
            <div key={item.id.videoId} className={styles.youtubeDiv}>
              <a
                href={`https://www.youtube.com/watch?v=${item.id.videoId}`}
                target="_blank"
                className={styles.itemLink}
              >
                <h5 className={styles.youtubeTitle}>
                  {item.snippet.title.replace("&amp;", "")}
                </h5>
              </a>
              <YouTube
                opts={opts}
                videoId={item.id.videoId}
                className={styles.youTubeImg}
                src={item.snippet.thumbnails.medium.url}
                alt="AIK highlight"
              />
            </div>
          );
        })}
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default AikHighlights;
