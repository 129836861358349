import React, { useEffect, useState } from 'react';

const UpcomingGamesWidget = () => {
  const [height, setHeight] = useState('360px');

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.height) {
        const extraHeight = window.innerWidth < 768 ? 40 : 20;
        setHeight(Number(event.data.height) + extraHeight);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <div>
      <iframe
        src="/upcomingGames-widget.html"
        title="Upcoming Games Widget"
        style={{ border: 'none', width: '100%', height: `${height}px` }}
      />
    </div>
  );
};

export default UpcomingGamesWidget;