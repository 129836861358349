import React from 'react'
import PlayerId from '../../components/PlayerId';


function Bahoui() {

   const player = "37231";

     const initialYear = "2023"

  return (
    <>
    <PlayerId data={player} data2={initialYear}/>
    </>
  )
}

export default Bahoui
