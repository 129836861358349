import { useEffect } from 'react';

const AdBanner = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-6615971593953407"
     data-ad-slot="2886611347"
     data-ad-format="auto"
     data-full-width-responsive="true"
    ></ins>
  );
};

export default AdBanner;