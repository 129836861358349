import React from 'react'
import PlayerId from '../../components/PlayerId';


function RobinJansson() {

   const player = "50924";
   const initialYear = "2024"

  return (
    <>
    <PlayerId data={player} data2={initialYear}/>
    </>
  )
}

export default RobinJansson
