import React from 'react'
import PlayerId from '../../components/PlayerId';


function Valakari() {

   const player = "39365";

   const initialYear = "2024"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default Valakari
