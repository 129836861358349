import React from 'react';

const ResultsWidget = () => {
  return (
    <div>
      <iframe
        src="/lastResult-widget.html"
        title="Result Widget"
        style={{ border: 'none', width: '100%', minHeight: '220px' }}
      />
    </div>
  );
};

export default ResultsWidget;