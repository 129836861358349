import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import styles from "../css/Loading.module.css"

function Loading() {
  return (
    <div className={styles.pappyDiv}>
      <RotatingLines
  strokeColor="#ffdd00"
  strokeWidth="5"
  animationDuration="0.75"
  width="96"
  visible={true}
/>
    </div>
  )
}

export default Loading
