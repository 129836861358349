import React from 'react'
import PlayerId from '../../components/PlayerId';


function Forsberg() {

   const player = "198766";
   const initialYear = "2024"
   return (
     <>
     <PlayerId data={player} data2={initialYear}/>
     </>
   )
 }

export default Forsberg
