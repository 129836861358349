import React from "react";
import styles from "../css/OmOss.module.css";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/AIKs.webp";
import Footer from "../components/Footer";
import { Timeline } from 'react-twitter-widgets'

function OmOss() {
  const handleClick = () => {
    window.open("https://www.aikfotboll.se/");
  };
  const handleClick2 = () => {
    window.open("https://www.aikplus.se/");
  };
  const handleClick3 = () => {
    window.open("http://forum.svartgul.se/guestbook/");
  };
  const handleClick4 = () => {
    window.open("https://open.spotify.com/show/5vqApRVsoU1Vd6Xv0N9hJq");
  };
  const handleClick6 = () => {
    window.open("https://www.2091.se/");
  };
  const handleClick7 = () => {
    window.open("https://stportfolio.netlify.app/");
  };
  const handleClick8 = () => {
    window.open("https://svartamassan.se/");
  };
  const handleClick9 = () => {
    window.open("https://open.spotify.com/show/2h3VmW5pRK0Q5XGaw89CBm");
  };

  return (
    <div className={styles.parent}>
      <div className={styles.leftSide}>
        <h1 className={styles.playerName}>
          {" "}
          <Navbar />{" "}
        </h1>
        <img
          className={styles.logoImageOmOss}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>

        <h1 className={styles.aikStats}>Om oss</h1>

        <div className={styles.dataBox}>
          <p className={styles.dataText}>
            Syftet med denna webbsida är att erbjuda en samling av relevant
            statistik för spelarna i AIK fotboll på ett snyggt och lättillgängligt sätt. Som supporter är det viktigt
            att ha koll på prestationerna hos varje spelare i AIK tröjan.
            <br></br>
            <br></br>
            Vår webbsida hämtar all data från api-football och uppdateras
            automatiskt efter varje match. Det kan finnas tveksamheter i vissa
            data från lägre divisioner, exempelvis har jag noterat att Div 1 och
            nedåt ignoreras helt därför är mycket data från våra utlånade
            spelare längre ner i divisionerna tyvärr bristfällig. Datan för
            passningsprocent är också bristfällig, därför har jag valt att
            utelämna den, men om det blir bättre så adderar jag det längre fram.
            Dessutom finns ingen data för xG, men om det kommer i framtiden så
            kommer jag såklart lägga till det också. Statistiken för ”betyg”
            kommer från api-football och jag vet inte vad det baseras på, så ta
            det med en nypa salt.
            <br></br>
            <br></br>
            Har du en ide för en feature eller förbättring av sidan så tveka
            inte att kontakta mig så ska jag se om det finns tid/möjlighet att
            implementera det.
            <br></br>
            <br></br>
            Det är just nu lite kostnader kopplat till webbsidan i form av
            inhämtning av data så jag tittar på lite olika alternativ för att
            kunna finansiera det långsiktigt i form av eventuell reklam eller
            dylikt. Men jag ser först om det finns ett intresse för sidan.
          </p>
          <div className={styles.border}></div>
        </div>
        <h4 className={styles.links}>Länkar</h4>
        <a className={styles.smallLink} onClick={handleClick} href="">
          AIK Fotboll
        </a>
        <a className={styles.smallLink} onClick={handleClick2} href="">
          AIK Plus
        </a>
        <a className={styles.smallLink} onClick={handleClick3} href="">
          Gnagarforum
        </a>
        <a className={styles.smallLink} onClick={handleClick4} href="">
          Testa stör
        </a>
        <a className={styles.smallLink} onClick={handleClick9} href="">
          Radio Skytteholm
        </a>
        <a className={styles.smallLink} onClick={handleClick6} href="">
          2091
        </a>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Kontakt</h4>
        <a className={styles.smallLink} onClick={handleClick7} href="">
          Portfolio
        </a>
        <p className={styles.contact}>info@AIKstatistik.se</p>

        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.emblemImage}
            src={logo}
            height="250px"
            width="250px"
            alt="AIK logo"
          />
          <h1 className={styles.aikStats}>Om oss</h1>
          <h1 className={styles.info}>Info</h1>

          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
            Syftet med denna webbsida är att erbjuda en samling av relevant
            statistik för spelarna i AIK fotboll. Som supporter är det viktigt
            att ha koll på prestationerna hos varje spelare i AIK tröjan.
            <br></br>
            <br></br>
            Webbsidan hämtar all data från api-football och uppdateras
            automatiskt efter varje match. Det kan finnas tveksamheter i vissa
            data från lägre divisioner, exempelvis har jag noterat att Div 1 och
            nedåt ignoreras helt därför är mycket data från våra utlånade
            spelare längre ner i divisionerna tyvärr bristfällig. Datan för
            passningsprocent är också bristfällig, därför har jag valt att
            utelämna den, men om det blir bättre så adderar jag det längre fram.
            Dessutom finns ingen data för xG, men om det kommer i framtiden så
            kommer jag såklart lägga till det också. Statistiken för ”betyg”
            kommer från api-football och jag vet inte vad det baseras på, så ta
            det med en nypa salt.
            <br></br>
            <br></br>
            Har du en ide för en feature eller förbättring av sidan så tveka
            inte att kontakta mig så ska jag se om det finns tid/möjlighet att
            implementera det. Det är just nu lite kostnader kopplat till
            webbsidan i form av inhämtning av data så jag tittar på lite olika
            alternativ för att kunna finansiera det långsiktigt i form av
            eventuell reklam eller dylikt. Men jag ser först om det finns ett
            intresse för sidan.
          </h6>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>
        <h4 className={styles.links}>AIK-TIFO</h4>
        <p className={styles.dataText}>
          AIK-Tifo är en oberoende grupp AIK-supportrar som grundades 1998 med
          syftet att förbättra läktarrangemangen. De har inget organisatoriskt
          eller ekonomiskt samarbete med AIK. Deras Swish-nummer är{" "}
          <span className={styles.tifoColor}>1233443496</span>.
        </p>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Svarta Massan</h4>
        <p className={styles.dataText}>
          Som supporter till AIK och om du har pengar över varje månad är det
          din skyldighet att gå med i Svarta Massan, en ekonomisk förening som är
          skapad av AIK:are för AIK. Besök <a className={styles.smallLinkMassan} onClick={handleClick8} href="">
          Svarta Massan
        </a> för mer
          information.{" "}
        </p>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Twitter</h4>
        <div className={styles.twitterDiv}>
        <Timeline
  dataSource={{
    sourceType: 'profile',
    screenName: 'AIKstat'
  }}
  options={{
    height: '300',
    width: "auto",
    theme: "dark",
    chrome: "noheader"
  }}
  style={{ width: '100%' }}
/>
</div>
<div className={styles.border}></div>
      </div>
      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default OmOss;
