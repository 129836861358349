import React, { useState, useEffect } from "react";
import styles from "../css/FvsW.module.css";
import axios from "axios";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/AIKs.webp";
import logo2 from "../backgrounds/DIF.png";
import vs from "../backgrounds/vs.png";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// wikheim 15805
// fischer 1294

function FvsW() {
  const [selectedSeason, setSelectedSeason] = useState("2023");
  const [mainLeague, setMainLeague] = useState("");

  const [playersData, setPlayersData] = useState({});
  const [playersData2, setPlayersData2] = useState({});

  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);

  useEffect(() => {
    const options = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/players",
      params: { id: 1294, season: selectedSeason },
    };

    axios
      .request(options)
      .then(function (response) {
        const data = response.data;
        setPlayersData(data.response[0]);
        setLoaded(true);
        setMainLeague(data.response[0].statistics[0].league.name);
      })

      .catch(function (error) {
        console.error(error);
      });
    const options2 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/players",
      params: { id: 15805, season: selectedSeason },
    };

    axios
      .request(options2)
      .then(function (response) {
        const data = response.data;
        setPlayersData2(data.response[0]);
        setLoaded2(true);
      })

      .catch(function (error) {
        console.error(error);
      });
  }, []);

  // Leftside data

  if (loaded === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  let rating = 0;
  if (playersData.statistics[0].games.rating === null) {
    rating = 0;
  } else {
    rating = playersData.statistics[0].games.rating.slice(0, 4);
  }


  const name = playersData.player.name;
  const matches = playersData.statistics[0].games.appearences + 0;
  const minutes = playersData.statistics[0].games.minutes + 0;
  const substituteIn = playersData.statistics[0].substitutes.in + 0;
  const substituteOut = playersData.statistics[0].substitutes.out + 0;
  const goals = playersData.statistics[0].goals.total + 0;
  const assists = playersData.statistics[0].goals.assists + 0;
  const yellow = playersData.statistics[0].cards.yellow + 0;
  const red = playersData.statistics[0].cards.red + 0;
  const penaltyGoals = playersData.statistics[0].penalty.scored + 0;
  const missedPenalties = playersData.statistics[0].penalty.scored + 0;
  const totalPasses = playersData.statistics[0].passes.total + 0;
  const keyPasses = playersData.statistics[0].passes.key + 0;
  const blocks = playersData.statistics[0].tackles.blocks + 0;
  const interceptions = playersData.statistics[0].tackles.interceptions + 0;
  const wonDuels = playersData.statistics[0].duels.won + 0;
  const totalDuels = playersData.statistics[0].duels.total + 0;
  const fouls = playersData.statistics[0].fouls.committed + 0;
  const receivedFouls = playersData.statistics[0].fouls.drawn + 0;
  const shots = playersData.statistics[0].shots.total + 0;
  const shotsOn = playersData.statistics[0].shots.on + 0;
  const dribbles = playersData.statistics[0].dribbles.attempts + 0;
  const successDribbles = playersData.statistics[0].dribbles.success + 0;

  let goalsAverage = goals / matches;
  let goalsAverageSlice = 0;
  if (goalsAverage === 0 || goalsAverage === NaN) {
    goalsAverageSlice = 0;
  } else {
    goalsAverageSlice = goalsAverage.toString().substring(0, 5);
  }
  let pointsAverage = goals + assists / matches;
  let pointsAverageSlice = 0;
  if (pointsAverage === 0 || pointsAverage === NaN) {
    pointsAverageSlice = 0;
  } else {
    pointsAverageSlice = pointsAverage.toString().substring(0, 5);
  }

  //rightside data

  const image = playersData.player.photo;
  const firstName = playersData.player.firstname;
  const lastName = playersData.player.lastname;
  const nationality = playersData.player.nationality;
  const birthday = playersData.player.birth.date;
  const birthCity = playersData.player.birth.place;
  const age = playersData.player.age - 1;
  const height = playersData.player.height;
  const weight = playersData.player.weight;

  if (loaded2 === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  let rating2 = 0;
  if (playersData2.statistics[0].games.rating === null) {
    rating2 = 0;
  } else {
    rating2 = playersData2.statistics[0].games.rating.slice(0, 4);
  }

  const name2 = playersData2.player.name;
  const team2 = playersData2.statistics[0].team.name;
  const matches2 = playersData2.statistics[0].games.appearences + 0;
  const minutes2 = playersData2.statistics[0].games.minutes + 0;
  const substituteIn2 = playersData2.statistics[0].substitutes.in + 0;
  const substituteOut2 = playersData2.statistics[0].substitutes.out + 0;
  const goals2 = playersData2.statistics[0].goals.total + 0;
  const assists2 = playersData2.statistics[0].goals.assists + 0;
  const yellow2 = playersData2.statistics[0].cards.yellow + 0;
  const red2 = playersData2.statistics[0].cards.red + 0;
  const penaltyGoals2 = playersData2.statistics[0].penalty.scored + 0;
  const missedPenalties2 = playersData2.statistics[0].penalty.scored + 0;
  const totalPasses2 = playersData2.statistics[0].passes.total + 0;
  const keyPasses2 = playersData2.statistics[0].passes.key + 0;
  const blocks2 = playersData2.statistics[0].tackles.blocks + 0;
  const interceptions2 = playersData2.statistics[0].tackles.interceptions + 0;
  const wonDuels2 = playersData2.statistics[0].duels.won + 0;
  const totalDuels2 = playersData2.statistics[0].duels.total + 0;
  const fouls2 = playersData2.statistics[0].fouls.committed + 0;
  const receivedFouls2 = playersData2.statistics[0].fouls.drawn + 0;
  const shots2 = playersData2.statistics[0].shots.total + 0;
  const shotsOn2 = playersData2.statistics[0].shots.on + 0;
  const dribbles2 = playersData2.statistics[0].dribbles.attempts + 0;
  const successDribbles2 = playersData2.statistics[0].dribbles.success + 0;

  let goalsAverage2 = goals2 / matches2;
  let goalsAverageSlice2 = 0;
  if (goalsAverage2 === 0 || goalsAverage2 === NaN) {
    goalsAverageSlice2 = 0;
  } else {
    goalsAverageSlice2 = goalsAverage2.toString().substring(0, 5);
  }
  let pointsAverage2 = goals2 + assists2 / matches2;
  let pointsAverageSlice2 = 0;
  if (pointsAverage2 === 0 || pointsAverage2 === NaN) {
    pointsAverageSlice2 = 0;
  } else {
    pointsAverageSlice2 = pointsAverage2.toString().substring(0, 5);
  }

  const image2 = playersData2.player.photo;
  const firstName2 = playersData2.player.firstname;
  const lastName2 = playersData2.player.lastname;
  const nationality2 = playersData2.player.nationality;
  const birthday2 = playersData2.player.birth.date;
  const birthCity2 = playersData2.player.birth.place;
  const age2 = playersData2.player.age - 1;
  const height2 = playersData2.player.height;
  const weight2 = playersData2.player.weight;

  const handleClick = () => {
    window.open("https://www.dobb.tv/");
  };

  return (
    <div className={styles.parentParent}>
    <div className={styles.parent}>
      <Helmet>
        <title>
          {" "}
          Fischer vs Wikheim - Statistik & Fakta | AIKstatistik.se
        </title>
      </Helmet>
      <div className={styles.leftSide}>
        <Navbar />
        <div className={styles.leftSideTop}>
          <div className={styles.infoBox2}>
          <h1 className={styles.info}>
          <span className={styles.fischer}>Fischer </span> 
            <br /> VS <br /> <span className={styles.wikheim}>Wikheim</span> 
          </h1>
          <div className={styles.border}></div>
          <h6 className={styles.signing}>
            Inspirerad av 08 fotboll hos <a className={styles.smallLink} onClick={handleClick} href="">
            Dobb.tv
        </a>  satte jag upp en sida för
            Jesper Hoffmans vadslagning med Fabian Ahlstrand om vem som skulle
            göra bäst ifrån sig under årets allsvenska av Viktor Fischer och
            Gustav Wikheim. 
          </h6>
          <div className={styles.border}></div>
          </div>
          <h1 className={styles.playerName}> {name}</h1>
          <img
            className={styles.playerImages}
            width="250px"
            height="250px"
            src={image}
            alt="Player image"
          ></img>

          <h3 className={styles.bigMenu}>{mainLeague} </h3>
          <p className={styles.season}>Säsong: 2023</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.matches}>Matcher: {matches}</p>
          <p className={styles.minutes}>Minuter: {minutes}</p>
        </div>
        <div className={styles.flexbox}>
          <p className={styles.minutes}>Inbytt: {substituteIn}</p>
          <p className={styles.minutes}>Utbytt: {substituteOut}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Betyg: {rating}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Mål: {goals}</p>
          <p className={styles.goals}>Assist: {assists}</p>
        </div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Målsnitt: {goalsAverageSlice}</p>
          <p className={styles.goals}>Poängsnitt: {pointsAverageSlice}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p>Röda: {red}</p>
          <p>Gula: {yellow}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Straffar</h3>
        <div className={styles.flexbox}>
          <p>Straffmål: {penaltyGoals}</p>
          <p>Missade: {missedPenalties}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Passningar</h3>
        <div className={styles.flexbox}>
          <p>Totala: {totalPasses}</p>
          <p>Nyckelpassningar: {keyPasses}</p>
        </div>

        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Tacklingar</h3>
        <div className={styles.flexbox}>
          <p>Blockar: {blocks}</p>
          <p>Brytningar: {interceptions}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Vunna dueller: {wonDuels}</p>
          <p>Totala: {totalDuels}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Regelbrott</h3>
        <div className={styles.flexbox}>
          <p>Orsakade: {fouls}</p>
          <p>Mottagna: {receivedFouls}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Skott</h3>
        <div className={styles.flexbox}>
          <p>Totala: {shots}</p>
          <p>På mål: {shotsOn}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Dribblingar</h3>
        <div className={styles.flexbox}>
          <p>Försök: {dribbles}</p>
          <p>Lyckade: {successDribbles}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.factBoxDiv}>
          <h1 className={styles.factBoxLeft}>Faktaruta</h1>
          <p className={styles.nationality2}>
            Namn: {firstName} {lastName}
          </p>
          <p className={styles.nationality2}>Nationalitet: {nationality}</p>

          <p className={styles.nationality2}>Födelsedag: {birthday}</p>
          <p className={styles.nationality2}>Födelsestad: {birthCity}</p>
          <p className={styles.nationality2}>Ålder: {age}</p>
          <p className={styles.nationality2}>Längd: {height}</p>
          <p className={styles.nationality2}>Vikt: {weight}</p>
        </div>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.playerImage}
            width="260px"
            height="260px"
            src={vs}
            alt="Player image"
          ></img>
          <h1 className={styles.info}>
          <span className={styles.fischer}>Fischer </span> 
            <br /> VS <br /> <span className={styles.wikheim}>Wikheim</span> 
          </h1>
          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
            Inspirerad av 08 fotboll hos <a className={styles.smallLink} onClick={handleClick} href="">
            Dobb.tv
        </a> satte jag upp en sida för
            Jesper Hoffmans vadslagning med Fabian Ahlstrand om vem som skulle
            göra bäst ifrån sig under årets allsvenska av Viktor Fischer och
            Gustav Wikheim.
          </h6>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo2}
          height="70px"
          alt="dif logo"
        />
        <div className={styles.border}></div>
        <div className={styles.leftSideTop}>
          <h1 className={styles.playerName2}> {name2}</h1>
          <img
            className={styles.playerImages2}
            width="250px"
            height="250px"
            src={image2}
            alt="Player image"
          ></img>

          <h3 className={styles.bigMenu3}>{mainLeague} </h3>
          <p className={styles.season}>Säsong: 2023</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.matches}>Matcher: {matches2}</p>
          <p className={styles.minutes}>Minuter: {minutes2}</p>
        </div>
        <div className={styles.flexbox}>
          <p className={styles.minutes}>Inbytt: {substituteIn2}</p>
          <p className={styles.minutes}>Utbytt: {substituteOut2}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Betyg: {rating2}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Mål: {goals2}</p>
          <p className={styles.goals}>Assist: {assists2}</p>
        </div>
        <div className={styles.flexbox}>
          <p className={styles.goals}>Målsnitt: {goalsAverageSlice2}</p>
          <p className={styles.goals}>Poängsnitt: {pointsAverageSlice2}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.flexbox}>
          <p>Röda: {red2}</p>
          <p>Gula: {yellow2}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu2}>Straffar</h3>
        <div className={styles.flexbox}>
          <p>Straffmål: {penaltyGoals2}</p>
          <p>Missade: {missedPenalties2}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu2}>Passningar</h3>
        <div className={styles.flexbox}>
          <p>Totala: {totalPasses2}</p>
          <p>Nyckelpassningar: {keyPasses2}</p>
        </div>

        <div className={styles.border}></div>
        <h3 className={styles.bigMenu2}>Tacklingar</h3>
        <div className={styles.flexbox}>
          <p>Blockar: {blocks2}</p>
          <p>Brytningar: {interceptions2}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Vunna dueller: {wonDuels2}</p>
          <p>Totala: {totalDuels2}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu2}>Regelbrott</h3>
        <div className={styles.flexbox}>
          <p>Orsakade: {fouls2}</p>
          <p>Mottagna: {receivedFouls2}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu2}>Skott</h3>
        <div className={styles.flexbox}>
          <p>Totala: {shots2}</p>
          <p>På mål: {shotsOn2}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu2}>Dribblingar</h3>
        <div className={styles.flexbox}>
          <p>Försök: {dribbles2}</p>
          <p>Lyckade: {successDribbles2}</p>
        </div>
        <div className={styles.border}></div>
        <h1 className={styles.factBoxRight}>Faktaruta</h1>
        <h4 className={styles.nationality3}>
          Namn: {firstName2} {lastName2}
        </h4>
        <h4 className={styles.nationality3}>Nationalitet: {nationality2}</h4>

        <h4 className={styles.nationality3}>Födelsedag: {birthday2}</h4>
        <h4 className={styles.nationality3}>Födelsestad: {birthCity2}</h4>
        <h4 className={styles.nationality3}>Ålder: {age2}</h4>
        <h4 className={styles.nationality3}>Längd: {height2}</h4>
        <h4 className={styles.nationality3}>Vikt: {weight2}</h4>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo2}
          height="70px"
          alt="dif logo"
        />
      </div>

      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
    </div>
  );
}

export default FvsW;
