import React from 'react'
import axios from "axios";

function Test() {
  const options = {
    method: 'GET',
    url: 'https://aikstats-backend.onrender.com/searchTeam',
    params: {search: 'molde'},
  };
  
  axios.request(options).then(function (response) {
    console.log(response.data);
  }).catch(function (error) {
    console.error(error);
  });

  const options2 = {
    method: 'GET',
    url: 'https://aikstats-backend.onrender.com/searchPlayer',
    params: {team: '329', search: 'ellingsen'},
  };
  
  axios.request(options2).then(function (response) {
    console.log(response.data);
  }).catch(function (error) {
    console.error(error);
  });
  
  return (
    <div>Test</div>
  )
}

export default Test
