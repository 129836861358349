import React from 'react'
import PlayerIdGoalie from '../../components/PlayerIdGoalie';


function Brolin() {

   const player = "107230";
  const initialYear = "2024"

  return (
    <>
    <PlayerIdGoalie data={player} data2={initialYear}/>
    </>
  )
}

export default Brolin
