import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

import styles from "../css/DerbyStatistik.module.css";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/AIKs.webp";
import difLogo from "../backgrounds/DIF.png";
import bajenLogo from "../backgrounds/bajen.png";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import DerbyTabell from "../components/DerbyTabell";

function DerbyStatistik() {
  const [difData, setDifData] = useState([]);
  const [bajenData, setBajenData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [difStats, setDifStats] = useState([]);
  const [bajenStats, setBajenStats] = useState([]);

  useEffect(() => {
    const dif = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/h2h",
      params: { h2h: "377-364" },
    };
    axios
      .request(dif)
      .then(function (response) {
        setDifData(response.data.response);
      })
      .catch(function (error) {
        console.error(error);
      });
    const bajen = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/h2h",
      params: { h2h: "377-363" },
    };
    axios
      .request(bajen)
      .then(function (response) {
        setBajenData(response.data.response);
      })
      .catch(function (error) {
        console.error(error);
      });
    const options = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/statistics",
      params: { league: "113", season: 2023, team: "364" },
    };

    axios
      .request(options)
      .then(function (response) {
        const data = response.data;
        setDifStats(data.response);
        setLoaded(true);
      })
      .catch(function (error) {
        console.error(error);
      });
    const options2 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/statistics",
      params: { league: "113", season: 2023, team: "363" },
    };

    axios
      .request(options2)
      .then(function (response) {
        const data = response.data;
        setBajenStats(data.response);
        setLoaded2(true);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  if (loaded === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const mainLeague = difStats.league.name;

  const goalsDif = difStats.goals.for.total.total + 0;
  const homeGoalsDif = difStats.goals.for.total.home + 0;
  const awayGoalsDif = difStats.goals.for.total.away + 0;
  const goalsAgainstDif = difStats.goals.against.total.total + 0;
  const homeGoalsAgainstDif = difStats.goals.against.total.home + 0;
  const awayGoalsAgainstDif = difStats.goals.against.total.away + 0;
  const scoredPenaltiesDif = difStats.penalty.scored.total + 0;
  const missedPenaltiesDif = difStats.penalty.missed.total + 0;
  const wonHomeDif = difStats.fixtures.wins.home + 0;
  const wonAwayDif = difStats.fixtures.wins.away + 0;
  const losesHomeDif = difStats.fixtures.loses.home + 0;
  const losesAwayDif = difStats.fixtures.loses.away + 0;
  const drawsHomeDif = difStats.fixtures.draws.home + 0;
  const drawsAwayDif = difStats.fixtures.draws.away + 0;
  const cleansheetHomeDif = difStats.clean_sheet.home + 0;
  const cleansheetAwayDif = difStats.clean_sheet.away + 0;
  const cleansheetTotalDif = difStats.clean_sheet.total + 0;

  let totalRedCardsDif = 0;
  if (difStats.cards && difStats.cards.red) {
    const redCards = difStats.cards.red;
    for (const timeSpan in redCards) {
      if (redCards[timeSpan].total === null) continue;
      totalRedCardsDif += redCards[timeSpan].total;
    }
  }

  let totalYellowCardsDif = 0;
  if (difStats.cards && difStats.cards.yellow) {
    const yellowCards = difStats.cards.yellow;
    for (const timeSpan in yellowCards) {
      if (yellowCards[timeSpan].total === null) continue;
      totalYellowCardsDif += yellowCards[timeSpan].total;
    }
  }

  if (loaded2 === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const goalsBajen = bajenStats.goals.for.total.total + 0;
  const homeGoalsBajen = bajenStats.goals.for.total.home + 0;
  const awayGoalsBajen = bajenStats.goals.for.total.away + 0;
  const goalsAgainstBajen = bajenStats.goals.against.total.total + 0;
  const homeGoalsAgainstBajen = bajenStats.goals.against.total.home + 0;
  const awayGoalsAgainstBajen = bajenStats.goals.against.total.away + 0;
  const scoredPenaltiesBajen = bajenStats.penalty.scored.total + 0;
  const missedPenaltiesBajen = bajenStats.penalty.missed.total + 0;
  const wonHomeBajen = bajenStats.fixtures.wins.home + 0;
  const wonAwayBajen = bajenStats.fixtures.wins.away + 0;
  const losesHomeBajen = bajenStats.fixtures.loses.home + 0;
  const losesAwayBajen = bajenStats.fixtures.loses.away + 0;
  const drawsHomeBajen = bajenStats.fixtures.draws.home + 0;
  const drawsAwayBajen = bajenStats.fixtures.draws.away + 0;
  const cleansheetHomeBajen = bajenStats.clean_sheet.home + 0;
  const cleansheetAwayBajen = bajenStats.clean_sheet.away + 0;
  const cleansheetTotalBajen = bajenStats.clean_sheet.total + 0;

  let totalRedCardsBajen = 0;
  if (bajenStats.cards && bajenStats.cards.red) {
    const redCards = bajenStats.cards.red;
    for (const timeSpan in redCards) {
      if (redCards[timeSpan].total === null) continue;
      totalRedCardsBajen += redCards[timeSpan].total;
    }
  }

  let totalYellowCardsBajen = 0;
  if (bajenStats.cards && bajenStats.cards.yellow) {
    const yellowCards = bajenStats.cards.yellow;
    for (const timeSpan in yellowCards) {
      if (yellowCards[timeSpan].total === null) continue;
      totalYellowCardsBajen += yellowCards[timeSpan].total;
    }
  }

  return (
    <div className={styles.parent}>
      <Helmet>
        <title>AIKstatistik.se - Stockholmsderbyn, Derbystatistik</title>
      </Helmet>
      <div className={styles.leftSide}>
        <h1 className={styles.playerName}>
          {" "}
          <Navbar />{" "}
        </h1>
        <img
          className={styles.logoImageOmOss}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>

        <h1 className={styles.aikStats}>Stockholmsderbyn</h1>

        <div className={styles.dataBox}>
          <p className={styles.dataText}>
            Stockholmsderbyn betyder lite extra, det finns fyra matcher per år
            där det brinner till lite extra på gemene AIKare, ja och eventuella
            cupderbyn förstås. Det inom journalistiska kretsar så kallade
            ”Tvillingderbyt” vinner vi ju i stort sett varje gång (åtminstone
            när det är publik på matcherna), men derbyt mot Bajen har genom åren
            varit lite mer ovisst.
            <br></br>
            <br></br>
            1902 spelade vi vårt första derby mot Djurgården i fotboll och det
            har länge varit Skandinaviens största fotbollsmatch oavsett vad
            diverse löst folk nere i Malmö eller Köpenhamn säger. Det senaste
            decenniet har det däremot utvecklat sig till en pinsam historia för
            Djurgården då de enbart lyckats vinna någon enskild cupmatch och en
            allsvensk match utan publik. Vi får se om det håller i sig
            fortsättningsvis nu när Djurgården ryckt upp sig lite sportsligt.
            <br></br>
            <br></br>
            Derbyt mot Bajen är kanske inte ens vårt rivalmöte nummer två, det
            finns nog gott om AIKare som skulle hävda att matcherna mot
            åtminstone Göteborg väger tyngre, kanske även mot Malmö. Men senaste
            åren har Hammarby ryckt upp sig något och det har utvecklat sig till
            tuffa matcher där det inte alltid är givet att vi tar tre poäng.
            <br></br>
            <br></br>
            Därför har jag gjort en enskild sida där man kan se våra
            inbördesmöten senaste åren, men även kommande matcher. Jag tog mig
            även friheten att sätta upp så man kan följa årets allsvenska
            statistik för både Djurgården och Hammarby, det kan ju vara
            intressant att hålla lite extra koll på.
          </p>
          <div className={styles.border}></div>
          <DerbyTabell />
          <div className={styles.border}></div>
        </div>
        <img
          className={styles.emblemImagse}
          src={difLogo}
          height="250px"
          width="250px"
          alt="AIK logo"
        />

        <h5 className={styles.h2hTitle}>Möten</h5>
        <table className={styles.h2hTable}>
          <tbody>
            {difData
              .sort((a, b) => {
                return a.fixture.timestamp - b.fixture.timestamp;
              })
              .reverse()
              .slice(0, 20)
              .map((diffen) => {
                return (
                  <tr key={crypto.randomUUID()} className={styles.h2hP}>
                    <td className={styles.h2hHome}>
                      {(() => {
                        if (diffen.teams.home.name === "AIK stockholm") {
                          return (
                            <span>{diffen.teams.home.name.slice(0, -10)}</span>
                          );
                        } else if (
                          diffen.teams.home.name === "Djurgardens IF"
                        ) {
                          return <span>DIF</span>;
                        } else if (
                          diffen.teams.home.name === "Djurgardens IF"
                        ) {
                          return <span>DIF</span>;
                        } else if (diffen.teams.home.name === "AIK Stockholm") {
                          return (
                            <span>{diffen.teams.home.name.slice(0, -10)}</span>
                          );
                        } else {
                          return diffen.teams.home.name;
                        }
                      })()}
                    </td>
                    <td className={styles.h2hResult}> {diffen.goals.home}</td>
                    <td>-</td>
                    <td className={styles.h2hResult}> {diffen.goals.away}</td>
                    <td className={styles.h2hAway}>
                      {(() => {
                        if (diffen.teams.away.name === "AIK stockholm") {
                          return (
                            <span>{diffen.teams.away.name.slice(0, -10)}</span>
                          );
                        } else if (
                          diffen.teams.away.name === "Djurgardens IF"
                        ) {
                          return <span>DIF</span>;
                        } else if (
                          diffen.teams.away.name === "Djurgardens IF"
                        ) {
                          return <span>DIF</span>;
                        } else if (diffen.teams.away.name === "AIK Stockholm") {
                          return (
                            <span>{diffen.teams.away.name.slice(0, -10)}</span>
                          );
                        } else {
                          return diffen.teams.away.name;
                        }
                      })()}
                    </td>
                    <td className={styles.h2hDate}>
                      {dayjs(diffen.fixture.date).format("YY.MM.DD")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className={styles.border}></div>
        <h4 className={styles.links}>Statistik Djurgården</h4>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenuLeague}> {mainLeague} </h3>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Gjorda mål</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {homeGoalsDif} </p>
          <p>Borta: {awayGoalsDif} </p>
          <p>Totalt: {goalsDif} </p>
        </div>
        <div className={styles.border}></div>

        <h3 className={styles.bigMenu}>Insläppta mål</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {homeGoalsAgainstDif}</p>
          <p>Borta: {awayGoalsAgainstDif}</p>
          <p>Totalt: {goalsAgainstDif} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Straffar</h3>
        <div className={styles.flexbox}>
          <p>Straffmål: {scoredPenaltiesDif}</p>
          <p>Missade: {missedPenaltiesDif} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Matcher</h3>
        <div className={styles.flexbox}>
          <p>Vunna H: {wonHomeDif}</p>
          <p>Vunna B: {wonAwayDif}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Förlorade H: {losesHomeDif}</p>
          <p>Förlorade B: {losesAwayDif}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Kryss H: {drawsHomeDif}</p>
          <p>Kryss B: {drawsAwayDif}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Hållna nollor</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {cleansheetHomeDif} </p>
          <p>Borta: {cleansheetAwayDif} </p>
          <p>Totalt: {cleansheetTotalDif} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Kort</h3>
        <div className={styles.flexbox}>
          <p>Röda: {totalRedCardsDif} </p>
          <p>Gula: {totalYellowCardsDif} </p>
        </div>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.emblemImage}
            src={logo}
            height="200px"
            width="200px"
            alt="AIK logo"
          />
          <h1 className={styles.aikStats}>Stockholmsderbyn</h1>
          <h1 className={styles.info}>Info</h1>

          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
            Stockholmsderbyn betyder lite extra, det finns fyra matcher per år
            där det brinner till lite extra på gemene AIKare, ja och eventuella
            cupderbyn förstås. Det inom journalistiska kretsar så kallade
            ”Tvillingderbyt” vinner vi ju i stort sett varje gång (åtminstone
            när det är publik på matcherna), men derbyt mot Bajen har genom åren
            varit lite mer ovisst.
            <br></br>
            <br></br>
            1902 spelade vi vårt första derby mot Djurgården i fotboll och det
            har länge varit Skandinaviens största fotbollsmatch oavsett vad
            diverse löst folk nere i Malmö eller Köpenhamn säger. Det senaste
            decenniet har det däremot utvecklat sig till en pinsam historia för
            Djurgården då de enbart lyckats vinna någon enskild cupmatch och en
            allsvensk match utan publik. Vi får se om det håller i sig
            fortsättningsvis nu när Djurgården ryckt upp sig lite sportsligt.
            <br></br>
            <br></br>
            Derbyt mot Bajen är kanske inte ens vårt rivalmöte nummer två, det
            finns nog gott om AIKare som skulle hävda att matcherna mot
            åtminstone Göteborg väger tyngre, kanske även mot Malmö. Men senaste
            åren har Hammarby ryckt upp sig något och det har utvecklat sig till
            tuffa matcher där det inte alltid är givet att vi tar tre poäng.
            <br></br>
            <br></br>
            Därför har jag gjort en enskild sida där man kan se våra
            inbördesmöten senaste åren, men även kommande matcher. Jag tog mig
            även friheten att sätta upp så man kan följa årets allsvenska
            statistik för både Djurgården och Hammarby, det kan ju vara
            intressant att hålla lite extra koll på.
          </h6>
        </div>
        <div className={styles.hideBox}>
        <DerbyTabell />
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
        <div className={styles.border}></div>
        <div className={styles.bajenLogoDiv}>
          <img
            className={styles.emblemImagseBajen}
            src={bajenLogo}
            height="200px"
            width="200px"
            alt="AIK logo"
          />
        </div>
        <h5 className={styles.h2hTitle}>Möten</h5>
        <table className={styles.h2hTable}>
          <tbody>
            {bajenData
              .sort((a, b) => {
                return a.fixture.timestamp - b.fixture.timestamp;
              })
              .reverse()
              .slice(0, 20)
              .map((diffen) => {
                return (
                  <tr key={crypto.randomUUID()} className={styles.h2hP}>
                    <td className={styles.h2hHome}>
                      {(() => {
                        if (diffen.teams.home.name === "AIK stockholm") {
                          return (
                            <span>{diffen.teams.home.name.slice(0, -10)}</span>
                          );
                        } else if (diffen.teams.home.name === "Hammarby FF") {
                          return <span>Bajen</span>;
                        } else if (diffen.teams.home.name === "Hammarby FF") {
                          return <span>Bajen</span>;
                        } else if (diffen.teams.home.name === "AIK Stockholm") {
                          return (
                            <span>{diffen.teams.home.name.slice(0, -10)}</span>
                          );
                        } else {
                          return diffen.teams.home.name;
                        }
                      })()}
                    </td>
                    <td className={styles.h2hResult}> {diffen.goals.home}</td>
                    <td>-</td>
                    <td className={styles.h2hResult}> {diffen.goals.away}</td>
                    <td className={styles.h2hAway}>
                      {(() => {
                        if (diffen.teams.away.name === "AIK stockholm") {
                          return (
                            <span>{diffen.teams.away.name.slice(0, -10)}</span>
                          );
                        } else if (diffen.teams.away.name === "Hammarby FF") {
                          return <span>Bajen</span>;
                        } else if (diffen.teams.away.name === "Hammarby FF") {
                          return <span>Bajen</span>;
                        } else if (diffen.teams.away.name === "AIK Stockholm") {
                          return (
                            <span>{diffen.teams.away.name.slice(0, -10)}</span>
                          );
                        } else {
                          return diffen.teams.away.name;
                        }
                      })()}
                    </td>
                    <td className={styles.h2hDate}>
                      {dayjs(diffen.fixture.date).format("YY.MM.DD")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className={styles.border}></div>
        <h4 className={styles.links}>Statistik Bajen</h4>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenuLeague}> {mainLeague} </h3>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Gjorda mål</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {homeGoalsBajen} </p>
          <p>Borta: {awayGoalsBajen} </p>
          <p>Totalt: {goalsBajen} </p>
        </div>
        <div className={styles.border}></div>

        <h3 className={styles.bigMenu}>Insläppta mål</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {homeGoalsAgainstBajen}</p>
          <p>Borta: {awayGoalsAgainstBajen}</p>
          <p>Totalt: {goalsAgainstBajen} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Straffar</h3>
        <div className={styles.flexbox}>
          <p>Straffmål: {scoredPenaltiesBajen}</p>
          <p>Missade: {missedPenaltiesBajen} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Matcher</h3>
        <div className={styles.flexbox}>
          <p>Vunna H: {wonHomeBajen}</p>
          <p>Vunna B: {wonAwayBajen}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Förlorade H: {losesHomeBajen}</p>
          <p>Förlorade B: {losesAwayBajen}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Kryss H: {drawsHomeBajen}</p>
          <p>Kryss B: {drawsAwayBajen}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Hållna nollor</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {cleansheetHomeBajen} </p>
          <p>Borta: {cleansheetAwayBajen} </p>
          <p>Totalt: {cleansheetTotalBajen} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Kort</h3>
        <div className={styles.flexbox}>
          <p>Röda: {totalRedCardsBajen} </p>
          <p>Gula: {totalYellowCardsBajen} </p>
        </div>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default DerbyStatistik;
