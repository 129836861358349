import React from 'react'
import PlayerIdGoalie from '../../components/PlayerIdGoalie';


function Carlgren() {

   const player = "15684";
   const initialYear = "2023"

  return (
    <>
    <PlayerIdGoalie data={player} data2={initialYear}/>
    </>
  )
}


export default Carlgren