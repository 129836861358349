import React from 'react'
import PlayerIdGoalie from '../../components/PlayerIdGoalie';


function Linner() {

   const player = "48135";
   const initialYear = "2023"

   return (
     <>
     <PlayerIdGoalie data={player} data2={initialYear}/>
     </>
   )
 }
 
export default Linner